import { useContext, useState } from "react";
import AuthProvider from "../../context/AuthContext";
import styles from "./ClassifiedPublishPost.module.css";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import ClassifiedProvider from "../../context/ClassifiedContext";
import ClassifiedCategoriesSelect from "../../components/ClassifiedComponents/ClassifiedCategoriesSelect";
import ImageEditField from "../../components/reusableComponents/ImageEditField";

function ClassifiedPublishPost() {
  const { user } = useContext(AuthProvider);
  const { publishGig, loading, errorMsg, fieldChecker } = useContext(ClassifiedProvider);

  const [data, setData] = useState({
    title: "",
    description: "",
    price: "",
    category: "",
    subcategory: "",
    user: user["email"],
  });

  const [image, setImage] = useState();

  if (loading) return <SpinnerFullPage />;
  // if (errorMsg) return <ErrorMessage errorMessage={errorMsg} />;

  return (
    <div className={styles.publishPost}>
      <h1>Publish Your New Classified Post</h1>
      <form
        className={`${styles.form} ${errorMsg && styles.disable}`}
        onSubmit={(e) => publishGig(e, data, image, errorMsg)}
      >
        <div className={styles.smallInput}>
          <input
            type="text"
            value={data.title}
            placeholder="Title..."
            onChange={(e) => fieldChecker(e, "title", setData)}
          />
          <input
            type="number"
            placeholder="USD..."
            onChange={(e) => setData((current) => ({ ...current, price: e.target.value }))}
          />
        </div>
        <textarea
          placeholder="Description..."
          rows={10}
          value={data.description}
          onChange={(e) => fieldChecker(e, "description", setData)}
        ></textarea>
        <ClassifiedCategoriesSelect setData={setData} />
        <ImageEditField bucketName="classified" setImage={setImage} />

        {errorMsg && <ErrorMessage errorMessage={errorMsg} />}
        <button type="submit" className={errorMsg && styles.disable}>
          Upload
        </button>
      </form>
    </div>
  );
}

export default ClassifiedPublishPost;
