import { useContext } from "react";
import styles from "./AdModalDelete.module.css";
import AdvertiseProvider from "../../context/AdContext";
import { Link } from "react-router-dom";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

function AdModalDelete({ handleAskDelete, data }) {
  const { errorMsg, errorMessage, handleDelete } = useContext(AdvertiseProvider);
  const id = data.id;
  const author = data.user;
  console.log(errorMsg, errorMessage);
  return (
    <div className={`${styles.modal}`}>
      {errorMsg && <ErrorMessage errorMessage={errorMsg} />}
      <h3>
        Are you sure you want to delete this ad <Link to={`ad/${id}`}>({data.title})</Link>? This
        decision is permanent and you can't undo it.
      </h3>
      <button id={styles.cancel} onClick={handleAskDelete}>
        Cancel
      </button>
      <button id={styles.delete} onClick={() => handleDelete(id, author)}>
        Delete
      </button>
    </div>
  );
}

export default AdModalDelete;
