import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import AuthContext from "../../../context/AuthContext";

// Images
import chain from "../../../Images/chainpage_logo.png";
import userProfile from "../../../Images/user-profile.png";
import facebook from "../../../Images/facebook-icon.webp";
import instagram from "../../../Images/instagram-icon.webp";
import facebookGray from "../../../Images/facebook-gray.png";
import instagramGray from "../../../Images/instagram-gray.png";
import PlaceholderImage from "../../../Images/placeholderUser.png";
import UserEdit from "../../../Images/user-avatar-edit.png";
import { useUserInfo } from "../../../context/UserContext";
import ChatContext from "../../../context/ChatContext";

// PARENT PROFILES
export const ParentProfiles = ({ last, currentUser, urlChange }) => {
  const { getUser } = useUserInfo();
  const [userData, setUserData] = useState(null);
  const [userImage, setUserImage] = useState(userProfile);
  const navigate = useNavigate();
  console.log(userImage);
  useEffect(() => {
    getUser(currentUser, setUserData, setUserImage);
  }, [urlChange, currentUser, getUser]);

  return (
    <div
      className="link"
      onClick={() => {
        navigate(`/user/${userData?.[1]}`);
      }}
    >
      <div className="user-profile" id="other-users">
        <LazyLoadImage
          placeholderSrc={PlaceholderImage}
          effect="blur"
          src={userImage}
          alt="user profile"
          id="user-image"
          className="parent-profiles"
        />
        #{userData?.[0]}
      </div>
      {!last && <img src={chain} alt="chain" id="profile-chain" />}
    </div>
  );
};

// MAIN USER PROFILE
export const MainUserProfile = ({ mainUser, onMessageHover, onHoverLeave, urlChange }) => {
  const inviteLink = `/chainpage/invite/${mainUser}/`;

  const { handleCopyInviteLink, copyInviteLink } = useContext(AuthContext);
  const { handleContactUser } = useContext(ChatContext);
  const { getUser, currentUser } = useUserInfo();

  const [userData, setUserData] = useState(null);
  const [userImage, setUserImage] = useState(userProfile);
  const navigate = useNavigate();

  useEffect(() => {
    getUser(mainUser, setUserData, setUserImage);
  }, [urlChange, currentUser, getUser, mainUser]);

  console.log(userData);
  return (
    <div className="link">
      {userData && (
        <>
          <div className="my-user-profile user-profile">
            <LazyLoadImage
              src={userImage ? userImage : PlaceholderImage}
              alt="user profile"
              id="user-image"
              placeholderSrc={PlaceholderImage}
              effect="blur"
            />
            <div className="user-details">
              <div className="invite-section">
                <p id="invite-link">{inviteLink}</p>
                <button onClick={handleCopyInviteLink}>
                  {!copyInviteLink ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-clipboard-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-clipboard-check-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                    </svg>
                  )}
                </button>
              </div>

              <h3>
                {userData[3]} #{userData[0]}
              </h3>
              <div className="icons">
                {userData[5] ? (
                  <a href={userData[5]}>
                    <img src={facebook} loading="lazy" alt="facebook icon" />
                  </a>
                ) : (
                  <img src={facebookGray} loading="lazy" alt="facebook icon" />
                )}
                {userData[4] ? (
                  <a href={userData[4]}>
                    <img src={instagram} loading="lazy" alt="instagram icon" />
                  </a>
                ) : (
                  <img src={instagramGray} loading="lazy" alt="facebook icon" />
                )}
              </div>
              <p
                className="see-message"
                onMouseEnter={() => onMessageHover(userData[7])}
                onMouseLeave={onHoverLeave}
              >
                See message
              </p>
              {!currentUser ||
                (currentUser !== mainUser ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="#10435a"
                    className="bi bi-chat-left-dots-fill"
                    viewBox="0 0 16 16"
                    id="contact-user"
                    onClick={() => handleContactUser(mainUser)}
                  >
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                  </svg>
                ) : (
                  <div className="edit-profile" onClick={() => navigate("/edit-profile")}>
                    <img src={UserEdit} alt="user edit" />
                  </div>
                ))}
            </div>
          </div>
          <img src={chain} alt="chain" id="profile-chain" />
        </>
      )}
    </div>
  );
};
