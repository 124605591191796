import { useEffect, useRef, useState } from "react";
import DarkBlueBtn from "../../components/DarkBlueBtn";
import { useUserInfo } from "../../context/UserContext";
import EditImg from "../../Images/edit.png";
import styles from "./EditProfile.module.css";

function EditProfile() {
  const {
    getUser,
    currentUser,
    handleChange,
    handleFileUpload,
    editUserProfile,
    setUserData: setData,
    userData: contextUserData,
  } = useUserInfo();

  const [userData, setUserData] = useState();
  const [userImage, setUserImage] = useState();

  const inputFileRef = useRef(null);

  useEffect(() => {
    getUser(currentUser, setUserData, setUserImage);
  }, [currentUser, getUser, setUserData, setUserImage]);

  useEffect(
    function () {
      if (!contextUserData || !contextUserData?.image) return;

      if (!(contextUserData.image instanceof Blob)) return;
      const reader = new FileReader();

      reader.onload = function (e) {
        setUserImage(reader.result);
      };

      reader.readAsDataURL(contextUserData.image);
    },
    [contextUserData, userData]
  );
  useEffect(
    function () {
      if (userImage?.includes("data:image")) return;
      if (userData)
        setData({
          email: userData[2],
          username: userData[1],
          pageName: userData[3],
          image: userImage,
          instagram: userData[4],
          facebook: userData[5],
          message: userData[7],
        });
    },
    [userData, userImage, setData]
  );

  function handleSubmit(e) {
    e.preventDefault();
    editUserProfile();
  }

  function handleEditImage() {
    inputFileRef.current.click();
  }
  return (
    <div>
      <h1>Edit Profile</h1>
      <form onSubmit={handleSubmit} className={styles.editProfileForm}>
        <div>
          <label>Email</label>
          <input type="email" name="email" defaultValue={userData?.[2]} onChange={handleChange} />
        </div>

        <div>
          <label>Username</label>
          <input type="text" name="username" defaultValue={userData?.[1]} onChange={handleChange} />
        </div>

        <div>
          <label>Page Name</label>
          <input type="text" name="pageName" defaultValue={userData?.[3]} onChange={handleChange} />
        </div>

        <div>
          <label>Instagram</label>
          <input
            type="text"
            name="instagram"
            defaultValue={userData?.[4]}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Facebook</label>
          <input type="text" name="facebook" defaultValue={userData?.[5]} onChange={handleChange} />
        </div>

        <div className={styles.profileImageEdit} onClick={handleEditImage}>
          <label>Profile Picture</label>
          <input
            ref={inputFileRef}
            type="file"
            style={{ display: "none" }}
            name="image"
            onChange={handleFileUpload}
            accept="image/*"
          />
          <img src={userImage} alt="profile" id={styles.profileImage} />
          <img src={EditImg} id={styles.edit} alt="img" />
        </div>

        <DarkBlueBtn handleClick={handleSubmit}>Edit Profile</DarkBlueBtn>
      </form>
    </div>
  );
}

export default EditProfile;
