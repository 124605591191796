import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";

import styles from "./ClassifiedProductEdit.module.css";

import useSendGetRequest from "../../hooks/sendGetRequest";

import AuthProvider from "../../context/AuthContext";
import ClassifiedProvider from "../../context/ClassifiedContext";
import ClassifiedCategoriesSelect from "../../components/ClassifiedComponents/ClassifiedCategoriesSelect";
import ImageEditField from "../../components/reusableComponents/ImageEditField";

function ClassifiedProductEdit() {
  const { slug } = useParams();

  const { errorMsg, loading, data } = useSendGetRequest(`/api/classified-artile-detail/${slug}/`);

  const { user } = useContext(AuthProvider);
  const { handleEdit, fieldChecker, errorMsg: errorMessage } = useContext(ClassifiedProvider);

  const [editedData, setEditedData] = useState({
    title: "",
    description: "",
    price: "",
    user: user["email"],
    id: slug,
    image: "",
    category: "Books",
    subcategory: "History",
  });
  const [image, setImage] = useState();
  console.log(data?.[0].sub_category);
  // upload data in fields when loaded
  useEffect(
    function () {
      setEditedData((current) => ({
        ...current,
        title: data?.[0].title,
        description: data?.[0].description,
        price: data?.[0].price,
        image: data?.[0].image,
        category: data?.[0].category,
        subcategory: data?.[0].sub_category,
      }));
    },
    [data]
  );

  // display image name

  function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();

    const AJAXData = JSON.stringify({
      title: editedData.title,
      description: editedData.description,
      price: editedData.price,
      user: editedData.user,
      id: editedData.id,
      category: editedData.category,
      subcategory: editedData.subcategory,
    });
    formData.append("post_data", AJAXData);
    formData.append("image", image);
    handleEdit(formData);
  }

  if (errorMsg) return <ErrorMessage errorMessage={errorMsg} />;
  if (loading) return <SpinnerFullPage />;

  return (
    <div>
      <h1>Edit Classified Article</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.smallInput}>
          <input
            type="text"
            placeholder="Title..."
            value={editedData.title}
            onChange={(e) => fieldChecker(e, "title", setEditedData)}
          />
          <input
            type="number"
            placeholder="Price"
            value={editedData.price}
            onChange={(e) => setEditedData((current) => ({ ...current, price: e.target.value }))}
          />
        </div>
        <textarea
          placeholder="Description..."
          rows={10}
          value={editedData.description}
          onChange={(e) => fieldChecker(e, "description", setEditedData)}
        ></textarea>

        <ClassifiedCategoriesSelect
          category={editedData.category}
          subCategory={editedData.subcategory}
          setData={setEditedData}
        />

        <ImageEditField editedData={editedData.image} bucketName="classified" setImage={setImage} />
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        <button type="submit" className={errorMessage && styles.disable}>
          Upload
        </button>
      </form>
    </div>
  );
}

export default ClassifiedProductEdit;
