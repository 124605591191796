import ImgComponent from "../ImgComponent";
import styles from "./Ad.module.css";
import Bin from "../../Images/bin.png";
import { useContext, useState } from "react";
import AdModalDelete from "./AdModalDelete";
import SpinnerFullPage from "../FullPageLoader/SpinnerFullPage";
import AdvertiseProvider from "../../context/AdContext";

function Ad({ data }) {
  const styleImg = { width: "100%", borderRadius: "10px" };

  const [showModal, setShowModal] = useState(false);
  const { isAd } = useContext(AdvertiseProvider);

  function handleAskDelete(e) {
    e.preventDefault();
    setShowModal((current) => !current);
  }

  if (!data) return <SpinnerFullPage />;

  const isImage = isAd(data.file);

  return (
    <div className={`${styles.ad} `}>
      {showModal && <AdModalDelete data={data} />}
      {isImage ? (
        <ImgComponent image={data.file} alt="product" styleImg={styleImg} />
      ) : (
        <video width="100%" height="450px" autoPlay={true}>
          <source src={data.file} type="video/mp4" />
        </video>
      )}

      <div className={styles.adBottom}>
        <h3>{data.title}</h3>

        <img src={Bin} alt="bin" id={styles.bin} onClick={(e) => handleAskDelete(e, data)} />
      </div>
    </div>
  );
}

export default Ad;
