import { useContext } from "react";
import styles from "./ClassifiedCategory.module.css";
import ClassifiedProvider from "../../context/ClassifiedContext";

function Category({ data, category }) {
  const { handleCategorySelect } = useContext(ClassifiedProvider);

  return (
    <div className={styles.category}>
      <h3 onClick={() => handleCategorySelect(category)}>{category}</h3>
      {console.log(category)}
      <ul>
        {data[`${category}`].map((book) => (
          <li onClick={() => handleCategorySelect(book.name)}>{book.name}</li>
        ))}
      </ul>
    </div>
  );
}

export default Category;
