import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import { useUserInfo } from "./UserContext";

const ClassifiedContext = createContext();

export default ClassifiedContext;

export const ClassifiedProvider = ({ children }) => {
  const navigate = useNavigate();
  const { backendUrl } = useContext(AuthContext);
  const { currentUser } = useUserInfo();

  const [category, setCategory] = useState(null);

  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(null);

  function fieldChecker(e, fieldName, setData) {
    const value = e.target.value;

    let length;
    if (fieldName === "title") length = 100;
    if (fieldName === "description") length = 1000;

    if (value.length > length) {
      setErrorMsg(`The ${fieldName} can be maximum of ${length} words.`);
      return;
    }

    setErrorMsg(null);
    setData((current) => ({
      ...current,
      [fieldName]: value,
    }));
  }

  async function handleDelete(id, author) {
    try {
      const response = await fetch(`${backendUrl}/api/delete-post`, {
        method: "DELETE",
        body: JSON.stringify({
          currentUser,
          author: author,
          id,
        }),
      });

      const dataReceived = await response.json();

      if (!response.ok) {
        if (dataReceived["errorMsg"]) {
          throw new Error(dataReceived["errorMsg"]);
        } else {
          throw new Error("Error happened while getting your data.");
        }
      }

      navigate(`/classified`);
      setErrorMsg(null);
    } catch (error) {
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function publishGig(e, data, image, error) {
    e.preventDefault();
    if (error) return;
    setLoading(true);
    const formData = new FormData();

    const AJAXData = JSON.stringify({
      title: data.title,
      description: data.description,
      price: data.price,
      user: data.user,
      category: data.category,
      subcategory: data.subcategory,
    });
    formData.append("post_data", AJAXData);
    formData.append("image", image);

    try {
      const response = await fetch(`${backendUrl}/api/create-classified-article/`, {
        method: "POST",
        body: formData,
      });

      const dataReceived = await response.json();

      if (!response.ok) {
        console.log(dataReceived);
        if (dataReceived["errorMsg"]) {
          throw new Error(dataReceived["errorMsg"]);
        } else {
          throw new Error("Error happened while getting your data.");
        }
      }

      navigate(`/classified/${dataReceived["data"]}`);
      setErrorMsg(null);
    } catch (error) {
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleEdit(data) {
    try {
      const response = await fetch(`${backendUrl}/api/edit-classified-post/`, {
        method: "PUT",
        body: data,
      });

      const dataReceived = await response.json();

      if (!response.ok) {
        if (dataReceived["errorMsg"]) {
          throw new Error(dataReceived["errorMsg"]);
        } else {
          throw new Error("Error happened while getting your data.");
        }
      }
      console.log(dataReceived);
      const classifiedId = dataReceived["data"];

      navigate(`/classified/${classifiedId}`);
      setErrorMsg(null);
    } catch (error) {
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  }

  function handleCategorySelect(categorySelected) {
    setCategory(categorySelected);
    navigate("classified");
  }
  const contextData = {
    handleCategorySelect,
    category,
    handleEdit,
    loading,
    errorMsg,
    publishGig,
    handleDelete,
    fieldChecker,
  };
  return <ClassifiedContext.Provider value={contextData}>{children}</ClassifiedContext.Provider>;
};
