import { useNavigate } from "react-router-dom";
import styles from "./CreateNewAd.module.css";
import { useContext, useEffect, useState } from "react";
import AuthProvider from "../../context/AuthContext";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import ImageEditField from "../../components/reusableComponents/ImageEditField";
import useSendGetRequest from "../../hooks/sendGetRequest";

function CreateNewAd() {
  const navigate = useNavigate();
  const { user, backendUrl } = useContext(AuthProvider);
  const {
    data: classified,
    errorMsg: classifiedError,
    loading: loadingClassified,
  } = useSendGetRequest(`/api/get-my-classified/${user["user_id"]}/`);

  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    title: "",
    description: "",
    contact: "",
    classifiedArticle: "",
    user: "",
  });

  const [image, setImage] = useState();
  useEffect(
    function () {
      if (classified) {
        console.log(classified);
        setData({
          classifiedArticle: classified[0]?.id,
          user: user["email"],
        });
      }
    },
    [classified, user]
  );
  async function publishAd(e) {
    e.preventDefault();
    if (!image) {
      setErrorMsg("Video or image is required!");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    console.log(data.user);
    const AJAXData = JSON.stringify({
      title: data.title,
      description: data.description,
      contact: data.contact,
      user: data.user,
      classifiedArticle: data.classifiedArticle,
    });

    formData.append("post_data", AJAXData);
    formData.append("image", image);

    try {
      const response = await fetch(`${backendUrl}/api/create-ad-post/`, {
        method: "POST",
        body: formData,
      });

      const dataReceived = await response.json();

      if (!response.ok) {
        if (dataReceived["errorMsg"]) {
          throw new Error(dataReceived["errorMsg"]);
        } else {
          throw new Error("Error happened while getting your data.");
        }
      }

      navigate(`/ad/${dataReceived["data"]}`);
      setErrorMsg(null);
    } catch (error) {
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  }

  if (loading || loadingClassified) return <SpinnerFullPage />;
  if (classifiedError) return <ErrorMessage errorMessage={classifiedError} />;
  if (!data.classifiedArticle)
    return <ErrorMessage errorMessage={"Please first create Classified article"} />;
  console.log(classified);
  return (
    <div>
      <h1>Advertise your new amazing product or service!</h1>
      {errorMsg && <ErrorMessage errorMessage={errorMsg} />}
      <form className={styles.form} onSubmit={publishAd}>
        <div className={styles.smallInput}>
          <input
            type="text"
            placeholder="Title..."
            onChange={(e) => setData((current) => ({ ...current, title: e.target.value }))}
          />
          <input
            type="text"
            placeholder="Contact preference"
            onChange={(e) => setData((current) => ({ ...current, contact: e.target.value }))}
          />
        </div>
        <textarea
          placeholder="Description..."
          rows={10}
          onChange={(e) => setData((current) => ({ ...current, description: e.target.value }))}
        ></textarea>

        {!classified ? (
          <label>No classified articles published</label>
        ) : (
          <>
            <label>Connect to classified article</label>
            <select
              value={data.classifiedArticle}
              onChange={(e) =>
                setData((current) => ({ ...current, classifiedArticle: e.target.value }))
              }
            >
              {classified.map((item) => (
                <option value={item.id}>{item.title}</option>
              ))}
            </select>
          </>
        )}

        <ImageEditField setImage={setImage} bucketName={"ads"} />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
}

export default CreateNewAd;
