import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../../context/AuthContext";

const PaymentSuccess = () => {
  const { user, backendUrl } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
      return;
    }
    async function allowUser() {
      const email = user ? user["email"] : "";
      const urlParams = new URLSearchParams(window.location.search);
      const stripeSessionId = urlParams.get("session_id");

      await fetch(`${backendUrl}/api/payment-success/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, stripeSessionId: stripeSessionId }),
      });
    }
    allowUser();
  }, [user, backendUrl, navigate]);

  setTimeout(function () {
    navigate(`/user/null`);
  }, 2000);

  return (
    <div>
      <h1>Payment Successful, you can use Chainpage now!</h1>
    </div>
  );
};

export default PaymentSuccess;
