import { createContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const backendURL = "https://chainpage-aa5cbbfd5466.herokuapp.com";
// const backendURL = "http://127.0.0.1:8000";

const frontendUrl = "https://www.chainpage.io";
// const frontendUrl = "http://localhost:3000";

// const websocketURL = "ws://127.0.0.1:8000/ws/";
const websocketURL = "wss://chainpage-aa5cbbfd5466.herokuapp.com/ws/";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens") ? jwtDecode(localStorage.getItem("authTokens")) : null
  );

  // eslint-disable-next-line no-unused-vars
  const [authTokens, setTokens] = useState(() =>
    localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null
  );

  const [loginErrorMessage, setLoginErrorMessage] = useState(null);
  const navigate = useNavigate();

  // USER PARENT LIST
  const [userList, setUserList] = useState([]);
  const [dateJoined, setDateJoined] = useState();

  const [isLoading, setIsLoading] = useState(null);

  const [chatOpen, setChatOpen] = useState("");

  // LOGIN USER
  const loginUser = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(`${backendURL}/api/token/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: e.target.email.value, password: e.target.password.value }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(
          "Something went wrong! Please check if you typed correct password and email!"
        );
      }
      setTokens(data);
      setUser(jwtDecode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      setLoginErrorMessage(null);
      navigate(`/user/${user}/`);
    } catch (error) {
      setLoginErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // LOGOUT USER
  const logoutUser = () => {
    navigate("/login");

    setTokens(null);
    localStorage.removeItem("authTokens");
    setUser(null);
    setUserList([]);
    setDateJoined();
  };

  // COPY INVITE LINK

  // FINAL DATA
  const contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    loginErrorMessage: loginErrorMessage,
    setLoginErrorMessage: setLoginErrorMessage,
    setUserList: setUserList,
    userList: userList,
    backendUrl: backendURL,
    dateJoined: dateJoined,
    frontendUrl: frontendUrl,
    chatOpen: chatOpen,
    setChatOpen: setChatOpen,
    websocketURL: websocketURL,
    isLoading,
    setUser,
  };

  return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};
