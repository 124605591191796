import ImgComponent from "../../components/ImgComponent";
import styles from "./AdDetails.module.css";
import EditIcon from "../../Images/edit.png";
import Bin from "../../Images/bin.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import ChatProvider from "../../context/ChatContext";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import useSendGetRequest from "../../hooks/sendGetRequest";
import AdModalDelete from "../../components/AdvertiseComponents/AdModalDelete";
import { useUserInfo } from "../../context/UserContext";

<img src={Bin} alt="bin" id={styles.bin} />;

function AdDetailsPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { handleContactUser } = useContext(ChatProvider);

  const { currentUser, getInformationOfLoggedInUser } = useUserInfo();

  const { errorMsg, loading, data } = useSendGetRequest(`/api/ad-detail/${id}/`);
  const styleImg = { width: " 400px", borderRadius: "10px" };

  const [showModal, setShowModal] = useState(false);

  useEffect(
    function () {
      getInformationOfLoggedInUser();
    },
    [getInformationOfLoggedInUser]
  );

  function handleAskDelete() {
    setShowModal((current) => !current);
  }

  function handleEdit() {
    navigate("edit");
  }

  if (loading) return <SpinnerFullPage />;
  if (errorMsg) return <ErrorMessage errorMessage={errorMsg} />;
  // https://storage.googleapis.com/chainpage-media/ads/Screenshot_2024-05-02_at_16.08.52.png?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=aizat-190%40aizat-414910.iam.gserviceaccount.com%2F20240513%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240513T121057Z&X-Goog-Expires=120&X-Goog-SignedHeaders=host&X-Goog-Signature=8e1e80c85845bffb5d4d48a9b6827b8acfa7f4dba40b1cf82fe6b07750a3c9c86a386beda21ec569f81bf6bdf3229b5270f91843a7d276f59c87ed4d0b566d22b888e8cd442d53d9d022a1490afa430204cd7ff22fb6227363e18254863d20c9cb35d45287adf5ca989b02daa78d4ced15795a6f22c201d99cf3cb4a8a62d808f94de3a183936600b252f22cd5274690bd004aa281013efa5a4cd2dd2b3d2baaeba0fc8f34d937ad8fca6631a83a3f4fecf106453754c13b041ea77b7045efc27c598610a876cb770e532f202569d5dbe55bd792f0404f2df5ce0e2c78b8a5e8d0398422d41466e529ebf8969a1581c53072b6a253a04bcc9dd60674bb315678
  const author = data[0].user;
  const sameUser = currentUser === author;
  const fileName = data[0]?.file
    ?.replace("https://storage.googleapis.com/chainpage-media/ads/", "")
    .split("?X-Goog-Algorithm")[0];

  const isImage = fileName?.match(/\.(jpg|jpeg|png|gif)$/i);

  console.log(isImage);
  return (
    <div className={styles.adDetails}>
      {showModal && <AdModalDelete data={data[0]} />}
      <div className={`${showModal && "blur"}`}>
        <h1>{data[0].title}</h1>
        <div className={styles.adDescription}>
          <div className={styles.media}>
            {isImage ? (
              <ImgComponent image={data[0].file} alt="product" styleImg={styleImg} />
            ) : (
              <video width="800" height="600" controls autoPlay muted>
                <source src={data[0].file} type="video/mp4" />
              </video>
            )}
          </div>

          <div className={styles.textInfo}>
            <p id={styles.author}>
              Published by:
              <Link to={`/user/${data[0].user}`}> {sameUser ? "You" : data[0].user}</Link>
            </p>

            <p>{data[0].description}</p>
            <select>
              <option>{data[0].contact_preference}</option>
            </select>

            <p id={styles.classifiedArticle}>
              {data[0].classified_article ? (
                <>
                  Connected to:
                  <Link to={`/classified/${data[0].classified_article}`}>
                    {data[0].classified_title}
                  </Link>
                </>
              ) : (
                <p id={styles.red}>Not connected to classified article</p>
              )}
            </p>
            {sameUser && (
              <div className={styles.actions}>
                <img src={Bin} alt="bin" onClick={handleAskDelete} />
                <img src={EditIcon} alt="edit" onClick={handleEdit} />
              </div>
            )}
            {!sameUser && (
              <button onClick={() => handleContactUser(data[0].user)}>Contact seller</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdDetailsPage;
