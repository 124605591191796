import styles from "./SuccessMessage.module.css";

function SuccessMessage({ successMessage }) {
  return (
    <div className={styles.successMessage}>
      <h3>{successMessage}</h3>
    </div>
  );
}

export default SuccessMessage;
