import styles from "./DarkBlueBtn.module.css";

function DarkBlueBtn({ handleClick, children }) {
  return (
    <button className={styles.btn} onClick={handleClick}>
      {children}
    </button>
  );
}

export default DarkBlueBtn;
