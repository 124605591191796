import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";

const BottomStats = () => {
  const navigate = useNavigate();
  const [userBottomStats, setUserBottomStats] = useState();
  const { backendUrl, user } = useContext(AuthContext);

  const username = window.location.href.split("user/")[1].split("/")[0];

  useEffect(
    function () {
      async function fetchInviteUserData() {
        const response = await fetch(`${backendUrl}/api/get-invite-user-info/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: username }),
        });

        if (!response.ok) return;
        const data = await response.json();

        setUserBottomStats(data);
      }
      fetchInviteUserData();
    },
    [backendUrl, username]
  );

  return (
    <div className="bottom-stats dark-color-border">
      {user ? (
        <>
          <button id="join-btn" onClick={() => navigate("/residual-payouts/")}>
            My residual payouts
          </button>
        </>
      ) : (
        <>
          {userBottomStats && (
            <>
              <div className="box">
                <p>xxxx</p>
                <p>stats</p>
              </div>
              <div className="box">
                <p>{userBottomStats["memberOn"]}</p>
                <p>Page links</p>
              </div>
              <div className="box">
                <p>{userBottomStats["earned"]}</p>
                <p>Earned</p>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BottomStats;
