import { Link } from "react-router-dom";
import Ad from "./Ad";
import styles from "./Ads.module.css";
import useSendGetRequest from "../../hooks/sendGetRequest";
import SpinnerFullPage from "../FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import AuthProvider from "../../context/AuthContext";
import { useContext } from "react";

function Ads() {
  const { user } = useContext(AuthProvider);
  const user_id = user["user_id"];
  const { data, errorMsg, loading } = useSendGetRequest(`/api/ads-list/${user_id}`);

  if (loading || !data) return <SpinnerFullPage />;
  if (errorMsg) return <ErrorMessage errorMessage={errorMsg} />;
  if (data.length === 0)
    return <h1 className={styles.noAdsH1}>Publish new ad for FREE and achieve greatness!</h1>;

  return (
    <div className={styles.ads}>
      {data.map((ad) => (
        <Link to={`ad/${ad["id"]}`}>
          <Ad data={ad} />
        </Link>
      ))}
    </div>
  );
}

export default Ads;
