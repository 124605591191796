import { createContext, useCallback, useContext, useState } from "react";

const backendURL = "https://chainpage-aa5cbbfd5466.herokuapp.com/api/";
// const backendURL = "http://127.0.0.1:8000/api/";

const WebsiteContext = createContext();

function WebsiteInfoProvider({ children }) {
  const [promotionalVideoURL, setPromotionalVideoURL] = useState("");

  // WEBSITE INFORMATION
  const [numberOfPages, setNumberOfPages] = useState("");
  const [numberOfPayouts, setNumberOfPayouts] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  // GET WEBSITE INFO
  const fetchWebsiteInfo = useCallback(async function fetchWebsiteInfo() {
    try {
      setIsLoading(true);
      const response = await fetch(`${backendURL}get-website-info/`, {
        method: "GET",
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error("Error happened while getting websites data!");
      }

      setNumberOfPages(data["numberOfLinks"]);
      setNumberOfPayouts(data["amount_of_payouts"][0]["payout_amount"]);
      setPromotionalVideoURL(data["promotional-video"]);
    } catch (error) {
      console.error(error.message);
      setNumberOfPages(null);
      setNumberOfPayouts(null);
      setPromotionalVideoURL(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // FINAL DATA
  const contextData = {
    fetchWebsiteInfo: fetchWebsiteInfo,
    numberOfPayouts: numberOfPayouts,
    numberOfPages: numberOfPages,
    promotionalVideoURL: promotionalVideoURL,
    isLoading,
  };

  return <WebsiteContext.Provider value={contextData}>{children}</WebsiteContext.Provider>;
}

function useWebsitesInfo() {
  const context = useContext(WebsiteContext);
  if (context === undefined)
    throw new Error("WebsiteContext was used outside the WebsiteInfoProvider");
  return context;
}

export { WebsiteInfoProvider, useWebsitesInfo };
