import ClassifiedArticle from "./ClassifiedArticle";
import styles from "./ClassifiedProductsList.module.css";

function ClassifiedProductsList({ data }) {
  return (
    <div className={styles.products}>
      {data.map((article) => (
        <ClassifiedArticle article={article} />
      ))}
    </div>
  );
}

export default ClassifiedProductsList;
