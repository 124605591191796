import { createContext, useCallback, useContext, useState } from "react";
import AuthContext from "./AuthContext";

const backendURL = "https://chainpage-aa5cbbfd5466.herokuapp.com/api/";
const frontendUrl = "https://www.chainpage.io";
// const backendURL = "http://127.0.0.1:8000/api/";
// const frontendUrl = "http://localhost:3000";

const UserContext = createContext();

function UserInfoProvider({ children }) {
  const { user, setUser } = useContext(AuthContext);

  const [parentUser, setParentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dateJoined, setDateJoined] = useState(null);
  const [userList, setUserList] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [uuidLink, setUUIDLink] = useState("");
  const [copyError, setCopyError] = useState(null);

  const [userData, setUserData] = useState({
    email: "",
    pageName: "",
    username: "",
    image: {},
    instagram: "",
    facebook: "",
    message: "",
  });

  const [copyInviteLink, setCopyInviteLink] = useState(false);

  function handleCopyInviteLink() {
    if (userList[0] !== currentUser) return setCopyError("You can't copy someone elses link!");

    setCopyInviteLink(true);
    setCopyError("");
    navigator.clipboard.writeText(`${frontendUrl}/user/${currentUser}/${uuidLink}`);
  }

  const getParentProfiles = useCallback(
    async function getParentProfiles() {
      const urlUser = window.location.href.split("user/")[1].split("/")[0];
      const userToSend = urlUser === "null" ? user["email"] : urlUser;

      try {
        setLoading(true);
        const response = await fetch(`${backendURL}get-user-profile-info/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currentUser: userToSend,
          }),
        });
        if (!response.ok) {
          throw new Error("Error happened while getting parent profiles!");
        }
        const data = await response.json();
        console.log(data);
        setDateJoined(data["date_joined"]);
        setUserList(data["user_list"][0]);
        urlUser === "null" &&
          window.location.replace(`${window.location.origin}/user/${data["user_list"][0][0]}`);
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    },
    [user]
  );

  // GET USER DATA
  const getUser = useCallback(async function getUser(userRequest, updateState, updateUserImage) {
    const response = await fetch(`${backendURL}get-user-data/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: userRequest }),
    });

    if (!response.ok) return;

    const data = await response.json();

    updateState(data["user_data"][0]);
    updateUserImage(data["image"]);
    return { body: response.body };
  }, []);

  const getInformationOfLoggedInUser = useCallback(
    async function getInformationOfLoggedInUser(userToGet = user) {
      try {
        const response = await fetch(`${backendURL}get-loggedin-user-info/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: userToGet }),
        });
        if (!response.ok) {
          throw new Error("Error happened while getting your data.");
        }
        const data = await response.json();

        setCurrentUser(data["user_data"][0][0][0]);
        setUUIDLink(data["user_data"][0][0][1]);
        setParentUser(data["user_data"][1]);
      } catch (error) {
        console.error(error);
        setError("Error happened while getting your data");
      }
    },
    [user]
  );

  const handleFileUpload = (e) => {
    const value = e.target.files[0];
    console.log(value);
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: value,
    });
  };

  async function editUserProfile() {
    try {
      setLoading(true);

      if (!userData.pageName) {
        setError("Page name is required!");
        return;
      }
      if (!userData.email) {
        setError("Email is required!");
        return;
      }

      if (!userData.username) {
        setError("Username is !required");
        return;
      }

      const checkIfUsernameHasOnlyNumbers = /^\d+$/.test(userData.username);

      const checkIfUsernameHasSpace = +userData.username.trim().indexOf(" ") !== -1;

      const checkIfUsernameHasInvalidSpecialCharacters =
        // eslint-disable-next-line no-useless-escape
        /[ `!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/.test(userData.username);

      const checkIfUsernameConsistsOnlyOfSpecialCharacters =
        !!userData.username.match(/^[^a-zA-Z0-9]+$/);

      if (checkIfUsernameHasOnlyNumbers) {
        setError("Username can't be consisted of only numbers!");
        return;
      }
      if (checkIfUsernameHasSpace) {
        setError("Username must be one word!");
        return;
      }

      if (checkIfUsernameHasInvalidSpecialCharacters) {
        setError("Username can't have any special characters except _ and .");
        return;
      }
      if (checkIfUsernameConsistsOnlyOfSpecialCharacters) {
        setError("Username can't be consisted of only special characters!");
        return;
      }

      if (userData.facebook) {
        if (!userData.facebook.includes("https://www.facebook.com/")) {
          setError("Facebook URL is not correct!");
          return;
        }
      }

      if (userData.instagram) {
        if (!userData.instagram.includes("https://www.instagram.com/")) {
          setError("Instagram URL is not correct!");
          return;
        }
      }

      const formData = new FormData();

      const AJAXData = JSON.stringify({
        username: userData.username,
        email: userData.email,
        pageName: userData.pageName,
        facebook: userData.facebook,
        instagram: userData.instagram,
        message: userData.message,
        currentEmail: user.email,
      });
      console.log(user.email);
      formData.append("currentEmail", user.email);
      formData.append("post_data", AJAXData);
      formData.append("image", userData.image);

      const response = await fetch(`${backendURL}edit-user-profile/`, {
        method: "PUT",
        body: formData,
      });

      if (!response.ok) {
        // throw new Error("Error happened while getting your data.");
      }
      const dataResponse = await response.json();
      if (user.email !== userData.email) {
        console.log(user.email, userData.email);
        setUser((current) => ({ ...current, email: userData.email }));
      }
    } catch {
      console.error(error);
      setError("Error happened while getting your data");
    } finally {
      setLoading(false);
    }
  }

  // FINAL DATA
  const contextData = {
    parentUser,
    loading,
    error,
    dateJoined,
    userList,
    currentUser,
    uuidLink,
    getInformationOfLoggedInUser,
    getUser,
    getParentProfiles,
    copyInviteLink,
    copyError,
    handleCopyInviteLink,
    setCopyInviteLink,
    editUserProfile,
    userData,
    setUserData,
    handleChange,
    handleFileUpload,
  };

  return <UserContext.Provider value={contextData}>{children}</UserContext.Provider>;
}

function useUserInfo() {
  const context = useContext(UserContext);
  if (context === undefined) throw new Error("UserContext was used outside the UserInfoProvider");
  return context;
}

export { UserInfoProvider, useUserInfo };
