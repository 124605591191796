import React, { useState, useEffect, useContext } from "react";

import AuthContext from "../../context/AuthContext";

import PayoutsModal from "../../components/residualPayoutsComponents/PayoutsModal";
import PayoutMainInfo from "../../components/residualPayoutsComponents/PayoutMainInfo";
import ListOfPayouts from "../../components/residualPayoutsComponents/ListOfPayouts";

import "./ResidualPayouts.css";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import SuccessMessage from "../../components/reusableComponents/SuccessMessage";

function Payouts({ emailChanged }) {
  const { user, backendUrl } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [userBalance, setBalance] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [listOfPayouts, setListOfPayouts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [passowrdIsVisible, setPassowrdIsVisible] = useState(false);
  const [changeModalScreen, setChangeModalScreen] = useState(false);
  const [hasPayPalEmail, setHasPayPalEmail] = useState(false);

  useEffect(
    function () {
      async function getUserPayouts() {
        setLoading(true);
        try {
          const response = await fetch(`${backendUrl}/api/get-payouts/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: user }),
          });
          const data = await response.json();

          if (!response.ok) {
            throw new Error(
              "Looks like something went wrong while we tried to fetch list of your residual payouts."
            );
          }
          if (data["payouts"]) {
            setListOfPayouts(data["payouts"]);
          }
          setHasPayPalEmail(data["has_pay_pal_email"]);
          setBalance(data["balance"]);
          setWithdrawAmount(data["balance"]);
          setLoading(false);
          setError("");
        } catch (error) {
          console.error(error);
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
      getUserPayouts();
    },
    [setHasPayPalEmail, setBalance, setWithdrawAmount, setLoading, setError, user, backendUrl]
  );

  useEffect(
    function () {
      if (emailChanged) {
        setError("");
        setSuccessMessage("PayPal email has been successfuly changed!");
      }
    },
    [setError, setSuccessMessage, emailChanged]
  );

  async function handlePayout() {
    setLoading(true);
    try {
      const response = await fetch(`${backendUrl}/api/payout-user/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: user, withdraw: withdrawAmount }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data);
      }

      setSuccessMessage("Withdrawal successful!");
      setBalance((currentBalance) => currentBalance - withdrawAmount);
      setWithdrawAmount((current) => userBalance - current);
      setListOfPayouts((current) => [
        { amount: data["data"][0]["amount"], date: data["data"][0]["date"] },
        ...current,
      ]);
      setError(null);
    } catch (error) {
      console.error("Custom error", error);
      setError(error.message);
      setSuccessMessage(null);
    } finally {
      setLoading(false);
    }
  }

  function handleWithdrawAmountChange(e) {
    if (e.target.value > userBalance) return;
    setWithdrawAmount(e.target.value);
  }

  function handleOpenModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  async function handleIdentificationSubmit(e) {
    e.preventDefault();
    const email = e.target.email.value;
    if (email !== user["email"]) {
      setError("Authentication data is not valid. Recheck your email!");
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(`${backendUrl}/api/token/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: e.target.password.value,
        }),
      });
      if (!response.ok) {
        throw new Error();
      }
      setError("");
      setChangeModalScreen(true);
    } catch {
      setShowModal(false);
      setError("Identification failed!");
    } finally {
      setLoading(null);
    }
  }

  async function handleEmailChange(e) {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await fetch(`${backendUrl}/api/change-pay-pal-email/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: e.target.email.value, user: user }),
      });
      if (!response.ok) {
        throw new Error();
      } else {
        setSuccessMessage(
          "Verification link has been sent to your email! Please verify to complete email change"
        );
      }
      setError(null);
    } catch {
      setError("Email change failed!");
      setSuccessMessage(null);
    } finally {
      setLoading(null);
    }

    setShowModal(false);
    setChangeModalScreen(false);
  }

  if (loading) return <SpinnerFullPage />;

  return (
    <div className="my-residual-payouts">
      {error && <ErrorMessage errorMessage={error} />}
      {successMessage && <SuccessMessage successMessage={successMessage} />}
      {showModal && (
        <PayoutsModal
          changeModalScreen={changeModalScreen}
          handleIdentificationSubmit={handleIdentificationSubmit}
          setPassowrdIsVisible={setPassowrdIsVisible}
          passowrdIsVisible={passowrdIsVisible}
          handleEmailChange={handleEmailChange}
          handleCloseModal={handleCloseModal}
        />
      )}

      <div className={`inner-div  ${showModal && "blur"}`}>
        <h1>MY residual payouts</h1>

        <PayoutMainInfo
          handleWithdrawAmountChange={handleWithdrawAmountChange}
          handleOpenModal={handleOpenModal}
          userBalance={userBalance}
          handlePayout={handlePayout}
          withdrawAmount={withdrawAmount}
          hasPayPalEmail={hasPayPalEmail}
        />
        <ListOfPayouts listOfPayouts={listOfPayouts} />
      </div>
    </div>
  );
}

export default Payouts;
