import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import AuthContext from "../../context/AuthContext";

// CSS
import "./MembersInvite.css";

const AccountActivation = ({ permission, error }) => {
  const { backendUrl, user } = useContext(AuthContext);
  const navigate = useNavigate();
  console.log(error);
  function createCheckoutSession() {
    axios.post(`${backendUrl}/api/create-checkout-session/`, { user: user }).then((res) => {
      const sessionUrl = res["data"]["sessionUrl"];
      window.location.href = sessionUrl;
    });
  }

  function payPalMethod() {
    navigate("/paypal-subscription");
  }

  return (
    <div className="account-not-activated">
      <h1>Activate Account to use chainpage!</h1>
      {error && <p id="error">{error}</p>}
      <button
        onClick={createCheckoutSession}
        disabled={error === "Please activate email in order to continue."}
        className={error === "Please activate email in order to continue." && "disable"}
      >
        Complete payment
      </button>
      <button
        onClick={payPalMethod}
        disabled={error === "Please activate email in order to continue."}
        className={error === "Please activate email in order to continue." && "disable"}
      >
        Activate account with PayPal
      </button>
    </div>
  );
};

export default AccountActivation;
