import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import { useUserInfo } from "./UserContext";

const ChatContext = createContext();

export default ChatContext;

export const ChatProvider = ({ children }) => {
  const { user, backendUrl, setChatOpen } = useContext(AuthContext);
  const { currentUser } = useUserInfo();
  const navigate = useNavigate();

  const [chatList, setChatList] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  async function getListOfChats() {
    try {
      const email = user["email"];
      setLoading(true);
      const response = await fetch(`${backendUrl}/api/get-list-of-chats/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });
      const data = await response.json();

      if (response.status === 403 || response.status === 404) {
        navigate("/login", { replace: true });
      }

      if (response.ok) {
        const chats = data["chats"];
        console.log(chats);
        setChatList(chats);
      }
    } catch {
      console.error("Error occured");
      setErrorMsg("Oops, something went wrong while trying to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  async function handleContactUser(wantToChatWith) {
    try {
      console.log(wantToChatWith);
      const response = await fetch(`${backendUrl}/api/start-conversation/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ currentUser: currentUser, wantToChatWith: wantToChatWith }),
      });
      console.log("da");
      if (!response.ok) {
        throw new Error("Error happened while getting your data.");
      }
      console.log("ne");
      const dataReceived = await response.json();
      console.log(dataReceived);
      setChatOpen(dataReceived["chat"]);
      navigate(`/chats`);
    } catch (error) {
      console.error(error.message);
    }
  }

  const contextData = {
    handleContactUser,
    getListOfChats,
    chatList,
    setChatList,
    loading,
    errorMsg,
  };
  return <ChatContext.Provider value={contextData}>{children}</ChatContext.Provider>;
};
