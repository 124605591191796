import React, { useContext } from "react";
import AuthContext from "../../../context/AuthContext";

// CSS
import "./Main.css";

// Images
import closeIcon from "../../../Images/close.png";

const VideoBox = ({ onVideoIsRunning, videoIsRunning }) => {
  const { promotionalVideoURL } = useContext(AuthContext);

  function handleVideoClosing() {
    onVideoIsRunning(false);
  }

  return (
    <>
      {videoIsRunning && (
        <>
          <video controls autoPlay id="video">
            <source src={promotionalVideoURL} type="video/mp4" />
          </video>
          <img
            src={closeIcon}
            alt="closing icon"
            id="close-main-video"
            onClick={handleVideoClosing}
            loading="lazy"
          />
        </>
      )}
    </>
  );
};

export default VideoBox;
