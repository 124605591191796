import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../../../context/AuthContext";
import { useUserInfo } from "../../../context/UserContext";

const Search = () => {
  const { backendUrl, user, userList } = useContext(AuthContext);
  const { dateJoined } = useUserInfo();
  const navigate = useNavigate();
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    dayPeriod: "long",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  const [search, setSearch] = useState();
  const [searchResults, setSearchResults] = useState();

  useEffect(
    function () {
      const controller = new AbortController();
      async function fetchSearchResults() {
        try {
          const response = await fetch(`${backendUrl}/api/search-user/`, {
            method: "POST",
            signal: controller.signal,
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ search: search }),
          });

          const data = await response.json();
          setSearchResults(data["data"]);
        } catch (error) {
          console.error(error);
        }
      }
      if (search?.split("").length < 3) {
        setSearchResults();
        return;
      }
      if (search?.split("").length > 3) {
        fetchSearchResults();
      }

      return function () {
        controller.abort();
      };
    },
    [backendUrl, search]
  );

  return (
    <div className="joined-box dark-color-border">
      {user ? (
        <>
          <p>Members directory</p>
          <div className="search-directory">
            <form>
              <label>Link#</label>
              <input
                type="text"
                id="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />{" "}
              <button type="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="white"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </button>
            </form>
          </div>

          {searchResults && (
            <div className="search-results">
              {searchResults ? (
                <div
                  key={searchResults}
                  onClick={() => navigate(`/user/${searchResults["username"]}/`)}
                >
                  <img src={searchResults["profile_image"]} alt="user profile" />
                  <p>{searchResults["username"]}</p>
                </div>
              ) : (
                <p id="no-users">
                  No users with username <span> {search} </span>are found.
                </p>
              )}
            </div>
          )}
        </>
      ) : dateJoined ? (
        <>
          <p>{userList[0]} Joined</p>

          <p id="date-time">
            {new Intl.DateTimeFormat(navigator.language, options).format(new Date(dateJoined))} UTC
          </p>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Search;
