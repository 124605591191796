import { useNavigate } from "react-router-dom";

function SignupHeader({ invitedBy }) {
  const navigate = useNavigate();

  return (
    <>
      <h1>Signup</h1>
      <h5 onClick={() => navigate(`/user/${invitedBy}/${window.location.href.split("/")[5]}`)}>
        {invitedBy && (
          <>
            Invited by <span>{invitedBy}</span>
          </>
        )}
      </h5>
    </>
  );
}

export default SignupHeader;
