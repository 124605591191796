import React, { useState, useContext } from "react";

import AuthContext from "../../context/AuthContext";
import "./ResetPassword.css";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";

function SendEmailToResetPassword() {
  const { backendUrl } = useContext(AuthContext);
  const [email, setEmail] = useState();

  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [loading, setLoading] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();
    localStorage.setItem("email_to_reset_password", email);
    try {
      const response = await fetch(`${backendUrl}/api/password_reset/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(response);
      }
      setError(null);
      setSuccessMsg("Email confirmation has been sent to your email!");
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  }
  if (loading) return <SpinnerFullPage />;
  return (
    <div>
      <h1>Reset Password</h1>
      <form className="reset-password-form" onSubmit={handleSubmit}>
        <div>
          <label>Your account email</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="msg">
          {error && <p id="error">{error}</p>}
          {successMsg && <p id="success">{successMsg}</p>}
        </div>
        <button id="reset-password">Send email</button>
      </form>
    </div>
  );
}

export default SendEmailToResetPassword;
