import React from "react";
import { Link } from "react-router-dom";
// IMAGES

function TopBar({ roomName, profileImage }) {
  return (
    <div className="top-bar">
      <Link to={`/user/${roomName}`}>
        <img src={profileImage} alt="user-profile" className="user-icon" loading="lazy" />
        <h3>{roomName}</h3>
      </Link>
    </div>
  );
}

export default TopBar;
