/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import AuthContext from "../../../context/AuthContext";
import TopBar from "./topUserInfo";

import MessageBox from "./messageBox.js";

import "./Chat.css";
import PreviousMessages from "./previousMessages.js";

import axios from "axios";
import SpinnerFullPage from "../../FullPageLoader/SpinnerFullPage.js";
import { useUserInfo } from "../../../context/UserContext.js";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

const clientAPI = axios.create({
  baseURL: "https://chainpage-aa5cbbfd5466.herokuapp.com",
  // baseURL: "http://127.0.0.1:8000/",
});

function Chat({ pk, lightMode, setNotification }) {
  const navigate = useNavigate();

  const { user, backendUrl, websocketURL } = useContext(AuthContext);
  const { currentUser } = useUserInfo();
  const myRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [value, setValue] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [room, setRoom] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(null);
  const name = user["email"];
  const date = new Date().toLocaleDateString("en-US");
  const client = new W3CWebSocket(websocketURL + pk + "/");

  // Scroll to last message
  function executeScroll() {
    if (myRef.current) myRef.current.scrollIntoView({ behavior: "smooth" });
  }

  // SEND MESSAGE
  function onButtonClicked(e) {
    e.preventDefault();
    if (value) {
      executeScroll();
      client.send(
        JSON.stringify({
          text: value,
          sender: name,
          room: room,
          date: date,
        })
      );
      setValue("");
    }
    if (uploadedFiles.length === 0) return;
    console.log(uploadedFiles);
    const userData = {
      user: user["email"],
      chatPk: pk,
      image: uploadedFiles[0],
    };
    console.log(userData);
    async function uploadFiles() {
      clientAPI.post(`/api/upload-image/`, userData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    }

    uploadFiles();
  }

  function handleTextAreaStateChange(e) {
    if (value.length > 1000) return;
    setValue(e.target.value);
  }

  function formatDateTime(dateTime) {
    return new Date(dateTime).toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  useEffect(function () {
    executeScroll();
  });

  useEffect(
    function () {
      // GET CHAT DETAILS
      async function fetchChatData() {
        setLoading(true);
        try {
          console.log("test2");
          const response = await fetch(`${backendUrl}/api/get-chat-details/${pk}/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: user }),
          });

          const data = await response.json();
          if (!response.ok) {
            throw new Error(response);
          }

          const roomName = data["chat"]["username"];
          const profileImage = data["chat"]["profile_image"];
          const messagesReceived = data["messages"];

          setRoom(roomName);
          setProfileImage(profileImage);
        } catch (error) {
          if (error.status === 403) navigate(name ? `/user/${name}` : "/login");
          if (error.status === 404) navigate("/chats");
        } finally {
          setLoading(false);
        }
      }

      fetchChatData();
      executeScroll();
    },
    [backendUrl, pk, name, user, navigate]
  );
  const isMounted = useRef(false);

  useEffect(
    function () {
      client.onopen = () => {
        client.send(JSON.stringify({ type: "connect", user: user, pk: pk }));
        client.send(JSON.stringify({ type: "disconnect", user: user }));
      };
    },
    [pk, room]
  );

  useLayoutEffect(
    () => () => {
      client.send(JSON.stringify({ type: "disconnect", user: user }));
    },
    []
  );
  useEffect(function () {
    // WEBSOCKETS

    // RECEIVE MESSAGES FROM THE SERVER
    client.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      console.log(dataFromServer);
      if (!dataFromServer["type"]) {
        console.log("image coming");
        setValue("");
        executeScroll();
        setMessages((current) => [
          ...current,
          {
            message_content: dataFromServer.text,
            user_sender: dataFromServer.sender,
            message_time: formatDateTime(dataFromServer.date),
            user_email: dataFromServer.user_email,
            seen: dataFromServer["seen"] || dataFromServer.user_email !== user["email"],
            image: dataFromServer["image"],
          },
        ]);
        setUploadedFiles([]);
      } else {
        if (dataFromServer["type"] === "notification") {
          const user = dataFromServer["user"];
          const chat = dataFromServer["chat_pk"];
          console.log(user, chat);
          console.log(user, currentUser);

          // NOT WORKING AT THE MOMENT BECAUSE WEBSOCKET IS NOT CONNECTED TO CHAT AND NOTIFICATION WON'T BE RECEIVED
          if (user !== currentUser) {
            setNotification(+chat);
          }
        } else {
          const messagesReceived = dataFromServer["messages"];
          console.log(messagesReceived);
          const messages = messagesReceived.map((message) => {
            return {
              message_time: formatDateTime(message["message_time"]),
              message_content: message["message_content"],
              user_sender: message["user_sender"],
              user_email: message["user_email"],
              seen: message["seen"],
              image: message["image"],
            };
          });

          setMessages(messages);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <SpinnerFullPage />;
  return (
    <div className={`chat-opened ${lightMode ? "light" : "dark"}`}>
      <TopBar roomName={room} key={room} profileImage={profileImage} />
      <PreviousMessages messages={messages} user={user} />
      <p ref={myRef}></p>
      <MessageBox
        onButtonClicked={onButtonClicked}
        value={value}
        onMessageChange={handleTextAreaStateChange}
        onFileUpload={setUploadedFiles}
        uploadedFiles={uploadedFiles}
      />
    </div>
  );
}

export default Chat;
