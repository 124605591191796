import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../../context/AuthContext";

// COMPONENTS
import Chats from "../../components/chatComponents/chatList/chats";
import Chat from "../../components/chatComponents/chatBox/chat";

// CSS
import "./Chats.css";
import ChatContext from "../../context/ChatContext";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

function ChatsMain() {
  const { user, chatOpen, setChatOpen } = useContext(AuthContext);
  const { chatList, setChatList, loading, errorMsg, getListOfChats } = useContext(ChatContext);
  const [lightMode, setLightMode] = useState(false);
  const [notification, setNotification] = useState(0);

  const navigate = useNavigate();

  function handleThemeChange() {
    setLightMode((current) => !current);
  }

  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
      return;
    }
  }, [user, navigate]);

  useEffect(function () {
    getListOfChats();
  }, []);

  function handleOpenChat(pk) {
    setChatOpen(pk);
    setNotification();
    setChatList((current) =>
      current.map((chat) => (chat["pk"] === pk ? { ...chat, notifications: false } : chat))
    );
  }

  if (loading || chatList === null) return <SpinnerFullPage />;
  if (errorMsg) return <ErrorMessage errorMessage={errorMsg} />;

  if (chatList.length === 0 && !chatOpen)
    return (
      <main>
        <h1>No active chats, message someone and start conversation 🙌</h1>
      </main>
    );
  return (
    <main className="main-chat">
      <Chats
        handleOpenChat={handleOpenChat}
        chatOpen={chatOpen}
        handleThemeChange={handleThemeChange}
        lightMode={lightMode}
        notification={notification}
        chatList={chatList}
        setChatList={setChatList}
      />
      {chatOpen && (
        <Chat
          pk={chatOpen}
          key={chatOpen}
          lightMode={lightMode}
          setNotification={setNotification}
        />
      )}
    </main>
  );
}

export default ChatsMain;
