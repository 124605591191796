// CSS
import "./Main.css";

const MessageBox = ({ username, message }) => {
  return (
    <div className="user-message">
      <h3>{username} message</h3>
      <p>{message}</p>
    </div>
  );
};

export default MessageBox;
