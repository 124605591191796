import React from "react";

// CSS
import "./SideBar.css";

// Components
import Search from "./search.js";
import BottomStats from "./bottomStats.js";
import ButtonAndVideo from "./buttonAndVideo.js";

const SideBar = ({ onVideo }) => {
  return (
    <div className="left-sidebar dark-color-border">
      <ButtonAndVideo onVideo={onVideo} />
      <Search />
      <BottomStats />
    </div>
  );
};

export default SideBar;
