import React from "react";

// IMAGES
import chain from "../../Images/verticalAboutUSChain.png";

// CSS
import "./aboutUs.css";

function AboutUs() {
  const { innerHeight: height } = window;
  const chainHeightPX = 225;
  const loopTimes = Math.ceil(height / chainHeightPX + 225);

  return (
    <div className="about-us">
      <h1>About Us</h1>
      <p>
        <span>CHAINPAGE </span> was built to create a New Online Social Experience you not only
        enjoy but also benefit from. This Guiding Statement led, inspired and kept us on a 22 month
        course to develop & design a socialmedia site that PAYS YOU rather than Big Tech to increase
        our members. Generate massive referral income by simply sharing your page and with friends
        and family – Chainpage does the rest for you -Here’s How:
      </p>
      <p>
        <span>MULTI GENERATIONAL PAYOUTS </span>
        CHAINPAGE lets you literally make your fortune,   just by simply joining then sharing your
        page with  friends family and/or your contacts online. MULTI GENERATIONAL PAYOUTS Everytime
        someone joins through your page,   Chainpage pays you $1 and places your name as  member 2
        on every new page that joins through  you.
      </p>

      <p>
        <span>CHAINPAGE </span> was built to create a New Online Social Experience you not only
        enjoy but also benefit from. This Guiding Statement led, inspired and kept us on a 22 month
        course to develop & design a socialmedia site that PAYS YOU rather than Big Tech to increase
        our members. Generate massive referral income by simply sharing your page and with friends
        and family – Chainpage does the rest for you -Here’s How:
      </p>
      <p>
        <span>MULTI GENERATIONAL PAYOUTS </span>
        CHAINPAGE lets you literally make your fortune,   just by simply joining then sharing your
        page with  friends family and/or your contacts online. MULTI GENERATIONAL PAYOUTS Everytime
        someone joins through your page,   Chainpage pays you $1 and places your name as  member 2
        on every new page that joins through  you.
      </p>

      <p>
        <span>CHAINPAGE </span> was built to create a New Online Social Experience you not only
        enjoy but also benefit from. This Guiding Statement led, inspired and kept us on a 22 month
        course to develop & design a socialmedia site that PAYS YOU rather than Big Tech to increase
        our members. Generate massive referral income by simply sharing your page and with friends
        and family – Chainpage does the rest for you -Here’s How:
      </p>
      <p>
        <span>MULTI GENERATIONAL PAYOUTS </span>
        CHAINPAGE lets you literally make your fortune,   just by simply joining then sharing your
        page with  friends family and/or your contacts online. MULTI GENERATIONAL PAYOUTS Everytime
        someone joins through your page,   Chainpage pays you $1 and places your name as  member 2
        on every new page that joins through  you.
      </p>
      <div className="chains">
        {Array.from({ length: loopTimes }, (_, i) => (
          <img src={chain} alt="chain" id="chain" key={i} loading="lazy" />
        ))}
      </div>
    </div>
  );
}

export default AboutUs;
