import { React, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import logo from "../../Images/logo.png";
import chain from "../../Images/chainpage_logo.png";

import "./Navbar.css";
import { useUserInfo } from "../../context/UserContext";

const Navbar = () => {
  const { user, logoutUser, setSearchResults, parentUser } = useContext(AuthContext);
  const { currentUser } = useUserInfo();
  const navigate = useNavigate();
  return (
    <header>
      <nav className="navbar">
        <NavLink
          to={currentUser ? `/user/${currentUser}` : "/login"}
          onClick={() => setSearchResults([])}
          className="logo"
        >
          <img src={logo} alt="chain page logo" loading="lazy" />
          <p>chainpage</p>
        </NavLink>
        <ul className="nav-links">
          {user ? (
            <NavLink to="/contact-us/" className="nav-link">
              Contact us
            </NavLink>
          ) : (
            <NavLink to="/about-us/" className="nav-link">
              About us
            </NavLink>
          )}
          <NavLink to="/advertise" className="nav-link">
            Advertise
          </NavLink>
          <NavLink to="/classified" className="nav-link">
            Classified
          </NavLink>
          <NavLink to="/chats" className="nav-link">
            Chat
          </NavLink>
          <div className="vertical-line"></div>
          {user ? (
            <NavLink
              to="/login"
              onClick={() => {
                logoutUser();
              }}
              className="nav-link authentication"
            >
              Log Out
            </NavLink>
          ) : (
            <NavLink to="/login" className="nav-link authentication">
              Log In
            </NavLink>
          )}
        </ul>
        <div className="nav-invite-by">
          <img src={chain} alt="chain" loading="lazy" />
          <p>
            Invite by:
            <span
              onClick={() => {
                navigate(`/user/${parentUser}/`);
              }}
            >
              {parentUser}
            </span>
          </p>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
