import React, { useState, useEffect, useContext } from "react";

import AuthContext from "../../context/AuthContext";
import PasswordInput from "../../components/reusableComponents/PasswordInput";
import "./ResetPassword.css";
import { useNavigate } from "react-router-dom";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";

function ResetPassword() {
  const { backendUrl } = useContext(AuthContext);

  const [newPasswordValue, setNewPasswordValue] = useState();
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    const email = localStorage.getItem("email_to_reset_password");

    try {
      setLoading(true);
      const response = await fetch(`${backendUrl}/api/change_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          new_password: newPasswordValue,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data ? data : "Error happened, please try again!");

      setError(null);
      setSuccessMsg("Your password is changed successfully!");
      navigate("/login");
    } catch (error) {
      setError(error.message);
      setSuccessMsg(null);
    } finally {
      setLoading(false);
    }
  }
  if (loading) return <SpinnerFullPage />;

  return (
    <div>
      <h1>Reset Password</h1>
      <form className="reset-password-form" onSubmit={handleSubmit}>
        <div>
          <label>New Passowrd</label>
          <PasswordInput
            eyeColor="#000"
            key={2}
            passwordValue={newPasswordValue}
            handleChange={(e) => setNewPasswordValue(e.target.value)}
          />
        </div>
        <div className="msg">
          {error && <p id="error"> {error}</p>}
          {successMsg && <p id="success"> {successMsg}</p>}
        </div>
        <button id="reset-password">Reset password</button>
      </form>
    </div>
  );
}

export default ResetPassword;
