function AgreeOnTos({ setCheckbox }) {
  return (
    <div className="tos-div">
      <label id="tos-checkbox">
        Signing up signifies that you have read and agree to the Terms of Service
      </label>
      <input type="checkbox" value="1" onClick={() => setCheckbox((current) => !current)} />
    </div>
  );
}

export default AgreeOnTos;
