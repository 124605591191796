import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import { useUserInfo } from "./UserContext";

const AdvertiseContext = createContext();

export default AdvertiseContext;

export const AdvertiseProvider = ({ children }) => {
  const { backendUrl } = useContext(AuthContext);
  const { currentUser } = useUserInfo();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState(false);
  const [currentAdLink, setCurrentAdLink] = useState({ link: null, id: 0 });
  const [loading, setLoading] = useState(false);

  function isAd(file) {
    const fileName = file
      ?.replace("https://storage.googleapis.com/chainpage-media/ads/", "")
      .split("?X-Goog-Algorithm")[0];

    return fileName?.match(/.(jpg|jpeg|png|gif)$/i);
  }
  async function getNewAds() {
    try {
      setLoading(true);
      const response = await fetch(
        `${backendUrl}/api/get-new-ad/${currentAdLink["id"]}/${currentUser}/`
      );
      const data = await response.json();

      if (!response.ok) {
        if (data["errorMsg"]) {
          throw new Error(data["errorMsg"]);
        } else {
          throw new Error("Error happened while fetching data.");
        }
      }

      setCurrentAdLink({ link: data["data"]["link"], id: data["data"]["id"] });
      return data["data"];
    } catch (error) {
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleEdit(data, id) {
    try {
      const response = await fetch(`${backendUrl}/api/edit-ad-post/`, {
        method: "PUT",
        body: data,
      });

      const dataReceived = await response.json();

      if (!response.ok) {
        if (dataReceived["errorMsg"]) {
          throw new Error(dataReceived["errorMsg"]);
        } else {
          throw new Error("Error happened while getting your data.");
        }
      }

      navigate(`/advertise/ad/${id}`);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCreate(data) {
    try {
      const response = await fetch(`${backendUrl}/api/create-ad-post/`, {
        method: "POST",
        body: data,
      });

      const dataReceived = await response.json();

      if (!response.ok) {
        if (dataReceived["errorMsg"]) {
          throw new Error(dataReceived["errorMsg"]);
        } else {
          throw new Error("Error happened while getting your data.");
        }
      }
      console.log(response);

      // navigate(`/advertise`);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDelete(id, author) {
    try {
      const response = await fetch(`${backendUrl}/api/delete-ad-post/`, {
        method: "DELETE",
        body: JSON.stringify({
          currentUser,
          author,
          id,
        }),
      });

      const dataReceived = await response.json();

      if (!response.ok) {
        console.log(dataReceived);
        if (dataReceived["errorMsg"]) {
          throw new Error(dataReceived["errorMsg"]);
        } else {
          throw new Error("Error happened while deleting.");
        }
      }

      navigate(`/advertise`);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  const contextData = {
    handleDelete,
    handleCreate,
    handleEdit,
    getNewAds,
    currentAdLink,
    isAd,
    loading,
    errorMessage,
    errorMsg,
  };
  return <AdvertiseContext.Provider value={contextData}>{children}</AdvertiseContext.Provider>;
};
