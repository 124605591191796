import { useEffect, useState } from "react";
import styles from "./ImageEditField.module.css";

function ImageEditField({ editedData, bucketName, setImage }) {
  const [fileName, setFileName] = useState("No file selected");

  useEffect(
    function () {
      if (editedData) {
        setFileName(
          editedData
            .replace(`https://storage.googleapis.com/chainpage-media/${bucketName}`, "")
            .split("?X-Goog-Algorithm")[0]
        );
      }
    },
    [editedData, bucketName]
  );

  function changeFile(e) {
    if (!e.target.files[0]) return;
    setImage(e.target.files[0]);
    setFileName(e.target.files[0].name);
  }
  console.log(bucketName);
  const accept = bucketName === "ads" ? "video/*, image/*" : "image/*";

  return (
    <label id={styles.fileInput}>
      Upload image or video
      <span>({fileName})</span>
      <input type="file" onChange={changeFile} accept={accept} />
    </label>
  );
}

export default ImageEditField;
