import { useNavigate } from "react-router-dom";
import DarkBlueBtn from "../../components/DarkBlueBtn";
import Ads from "../../components/AdvertiseComponents/Ads";

function Advertise() {
  const navigate = useNavigate();

  function handleNewAdBtn() {
    navigate("new-ad");
  }
  return (
    <div>
      <h1>Advertise your services or products</h1>
      <DarkBlueBtn handleClick={handleNewAdBtn}>Create new ad</DarkBlueBtn>
      <Ads />
    </div>
  );
}

export default Advertise;
