import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-indigo/theme.css";

function ListOfPayouts({ listOfPayouts }) {
  return (
    <div className="list-of-payouts">
      <h1>Payout History</h1>
      <DataTable
        value={listOfPayouts}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: "50rem" }}
        showGridlines
        stripedRows
        className="payout-history-table"
      >
        <Column field="date" header="Date"></Column>
        <Column field="amount" header="Amount ($)"></Column>
      </DataTable>
    </div>
  );
}

export default ListOfPayouts;
