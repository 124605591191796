import React, { useContext } from "react";
import ChatItem from "./chatItem";
import ChatContext from "../../../context/ChatContext";

function Chats({ handleOpenChat, chatOpen, handleThemeChange, lightMode, notification }) {
  const { chatList } = useContext(ChatContext);

  return (
    <div>
      <div className="dark-light-mode">
        <label className="switch">
          <input type="checkbox" checked={lightMode} onClick={() => handleThemeChange()} />
          <span className="slider round"></span>
        </label>
      </div>
      {chatList && (
        <div className={`chats ${lightMode ? "light" : "dark"}`}>
          {chatList.map((chat) => (
            <ChatItem
              chat={chat}
              handleOpenChat={handleOpenChat}
              chatOpen={chatOpen}
              userProfileImage={chat["user_profile_image"]}
              notification={notification}
              key={chat["pk"]}
              className="active"
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Chats;
