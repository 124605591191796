import React, { useState, useEffect } from "react";

// Components
import SideBar from "../../components/homePageComponents/sideBar/sideBar.js";
import Main from "../../components/homePageComponents/Main/mainComponent.js";
import VideoBox from "../../components/homePageComponents/Main/Video.js";

import AccountActivation from "./ActivateAccount.js";

// CSS
import "./MembersInvite.css";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage.js";
import { useUserInfo } from "../../context/UserContext.js";

const MembersInvite = ({ permission, error, isLoading }) => {
  const { getParentProfiles, getInformationOfLoggedInUser } = useUserInfo();

  const [videoIsRunning, setVideoIsRunning] = useState(false);

  const url = window.location.pathname.split("/")[2];

  function handleVideoPlay() {
    setVideoIsRunning(true);
  }
  console.log(permission);
  // GET PARENT PROFILES
  useEffect(() => {
    getParentProfiles();
    getInformationOfLoggedInUser();
  }, [url, getParentProfiles, getInformationOfLoggedInUser]);

  if (isLoading) return <SpinnerFullPage />;
  return (
    <>
      {permission ? (
        <main className="main">
          <SideBar onVideo={handleVideoPlay} />
          <Main urlChange={url} />
          <VideoBox videoIsRunning={videoIsRunning} onVideoIsRunning={setVideoIsRunning} />
        </main>
      ) : (
        <AccountActivation error={error} />
      )}
    </>
  );
};

export default MembersInvite;
