import React from "react";

// IMAGES
import Seen from "../../../Images/read.png";
import Sent from "../../../Images/check.png";

function Message({ currentUser, message }) {
  const sentBy = currentUser === message.user_email;
  console.log(message["seen"]);
  console.log(message["image"]);
  console.log(message);
  return (
    <div className={`message-wrapper ${sentBy ? "my-user" : "other-user"}`}>
      <div className="message">
        <div className="content">
          {message["image"] && (
            <img src={message["image"]} alt="user-sent" id="user-sent-image" loading="lazy" />
          )}
          <p>{message["message_content"]}</p>
        </div>
        {message["seen"] ? (
          <img src={Seen} alt="read" id="check" loading="lazy" />
        ) : (
          <img src={Sent} alt="read" id="check" loading="lazy" />
        )}
      </div>
      <p id="message-time">{message["message_time"]}</p>
    </div>
  );
}

export default Message;
