import "./Error404.css";

function Error404({ errorMsg }) {
  return (
    <div className="error">
      <h1>Error 404 </h1>
      <p>{errorMsg ? errorMsg : "Page not found"}</p>
    </div>
  );
}

export default Error404;
