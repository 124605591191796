import { useEffect } from "react";

// CSS
import "./Main.css";

// Images
import chain from "../../../Images/chainpage_logo.png";
import { useWebsitesInfo } from "../../../context/WebsiteInfoContext";

const TopCircle = () => {
  const { fetchWebsiteInfo, numberOfPages, numberOfPayouts } = useWebsitesInfo();

  useEffect(() => {
    fetchWebsiteInfo();
  }, [fetchWebsiteInfo]);

  return (
    <div className="top-circle dark-color-border">
      <div className="data">
        <p>{numberOfPages}</p>
        <p>Total members</p>
      </div>
      <div id="top-chain">
        <img src={chain} alt="chain" loading="lazy" />
      </div>
      <div className="data">
        <p>{numberOfPayouts}$</p>
        <p>Total Payouts</p>
      </div>
    </div>
  );
};

export default TopCircle;
