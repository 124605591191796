import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../context/AuthContext";

import NextPreviousBtns from "./NextPreviousBtns";
import AgreeOnTos from "./AgreeOnTos";
import PasswordInput from "../reusableComponents/PasswordInput";
import SpinnerFullPage from "../FullPageLoader/SpinnerFullPage";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: "https://chainpage-aa5cbbfd5466.herokuapp.com",
  // baseURL: "http://127.0.0.1:8000/",
});

function SignupForm({ onRegisterError, onInvitedBy, invitedBy }) {
  const navigate = useNavigate();
  const { loginUser, user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(true);

  const [box, setBox] = useState(1);
  const [data, setData] = useState({
    email: "",
    pageName: "",
    username: "",
    password: "",
    image: "",
    instagram: "",
    facebook: "",
    message: "",
  });

  function handleNext() {
    setBox((currentBox) => currentBox + 1);
  }

  function handlePrev() {
    setBox((currentBox) => currentBox - 1);
  }

  const handleFileUpload = (e) => {
    const value = e.target.files[0];
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleChange = (e) => {
    onRegisterError(null);
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!data.pageName) {
      onRegisterError("Page name is required!");
      return;
    }
    if (!data.email) {
      onRegisterError("Email is required!");
      return;
    }
    if (!data.password) {
      onRegisterError("Password is required!");
      return;
    }
    if (!data.username) {
      onRegisterError("Username is !required");
      return;
    }

    const checkIfUsernameHasOnlyNumbers = /^\d+$/.test(data.username);

    const checkIfUsernameHasSpace = +data.username.trim().indexOf(" ") !== -1;

    const checkIfUsernameHasInvalidSpecialCharacters =
      // eslint-disable-next-line no-useless-escape
      /[ `!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/.test(data.username);

    const checkIfUsernameConsistsOnlyOfSpecialCharacters = !!data.username.match(/^[^a-zA-Z0-9]+$/);

    if (checkIfUsernameHasOnlyNumbers) {
      onRegisterError("Username can't be consisted of only numbers!");
      return;
    }
    if (checkIfUsernameHasSpace) {
      onRegisterError("Username must be one word!");
      return;
    }

    if (checkIfUsernameHasInvalidSpecialCharacters) {
      onRegisterError("Username can't have any special characters except _ and .");
      return;
    }
    if (checkIfUsernameConsistsOnlyOfSpecialCharacters) {
      onRegisterError("Username can't be consisted of only special characters!");
      return;
    }

    if (data.facebook) {
      if (!data.facebook.includes("https://www.facebook.com/")) {
        onRegisterError("Facebook URL is not correct!");
        return;
      }
    }

    if (data.instagram) {
      if (!data.instagram.includes("https://www.instagram.com/")) {
        onRegisterError("Instagram URL is not correct!");
        return;
      }
    }

    const userData = {
      email: data.email,
      pageName: data.pageName,
      username: data.username,
      password: data.password,
      image: data.image,
      instagram: data.instagram,
      facebook: data.facebook,
      activate: 0,
      invitedBy: invitedBy,
      message: data.message,
    };
    setLoading(true);
    client
      .post(`/api/register/`, userData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        loginUser(e);
        setLoading(false);
        onRegisterError(null);

        navigate("/user");
      })
      .catch((error) => {
        onRegisterError(error.response.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (window.location.href.includes("/invite/")) {
      const getUsernameInvited = window.location.href.split("/")[4];
      onInvitedBy(getUsernameInvited);
    } else {
      navigate("/user");
    }
  }, [onInvitedBy, navigate]);

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
      return;
    }
  }, [user, navigate]);

  if (loading) return <SpinnerFullPage />;
  return (
    <form method="post" onSubmit={handleSubmit}>
      <div className={box === 1 ? "active box" : "box"}>
        <h3>Page information</h3>
        <div>
          <div>
            <input
              type="text"
              placeholder="Page name*"
              onChange={handleChange}
              value={data.pageName}
              name="pageName"
            />
          </div>
          <div>
            <label id="file-upload">
              <input
                type="file"
                placeholder="Profile picture"
                onChange={handleFileUpload}
                name="image"
                accept="image/*"
              />
              {data.image ? `${data.image.name.substring(0, 13)}...` : "Add page icon"}
            </label>
          </div>
        </div>
        <div>
          <button id="next" type="button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>

      <div className={box === 2 ? "active box" : "box"}>
        <h3>Social media</h3>
        <div>
          <div>
            <input
              id="instagram"
              value={data.instagram}
              onChange={handleChange}
              name="instagram"
              placeholder="Instagram"
            />
          </div>
          <div>
            <input
              id="facebook"
              value={data.facebook}
              onChange={handleChange}
              name="facebook"
              placeholder="Facebook"
            />
          </div>
        </div>
        <NextPreviousBtns handleNext={handleNext} handlePrev={handlePrev} />
      </div>

      <div className={box === 3 ? "active box" : "box"}>
        <h3>Login information</h3>
        <div>
          <input
            type="email"
            placeholder="Email*"
            onChange={handleChange}
            value={data.email}
            name="email"
          />
          <input
            type="text"
            placeholder="Username*"
            onChange={handleChange}
            value={data.username}
            name="username"
          />
        </div>
        <PasswordInput handleChange={handleChange} passwordValue={data.password} />
        <NextPreviousBtns handleNext={handleNext} handlePrev={handlePrev} />
      </div>

      <div className={box === 4 ? "active box" : "box"}>
        <h3>Members messages</h3>

        <textarea
          onChange={handleChange}
          value={data.message}
          name="message"
          rows={10}
          cols={10}
        ></textarea>

        <div>
          <button id="back" type="button" onClick={handlePrev}>
            Back
          </button>
          <button type="submit" disabled={checkbox} className={checkbox && "disable-box"}>
            Create account!
          </button>
        </div>

        <AgreeOnTos setCheckbox={setCheckbox} />
      </div>
    </form>
  );
}

export default SignupForm;
