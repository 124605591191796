function NextPreviousBtns({ handlePrev, handleNext }) {
  return (
    <div>
      <button id="back" type="button" onClick={handlePrev}>
        Back
      </button>
      <button id="next" type="button" onClick={handleNext}>
        Next
      </button>
    </div>
  );
}

export default NextPreviousBtns;
