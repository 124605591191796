import React, { useState, useContext } from "react";
import AuthContext from "../../context/AuthContext";

import "./ContactUs.css";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
function Contact() {
  const { user } = useContext(AuthContext);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState(user?.["email"]);
  const [message, setMessage] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showSuccessMessage, setSuccessMessage] = useState("");

  const { backendUrl } = useContext(AuthContext);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(`${backendUrl}/api/contact-us/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, firstName, lastName, message }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data);
      }
      setSuccessMessage(data);
      setError("");
    } catch (error) {
      console.error(error.message);
      setError(error.message);
      setSuccessMessage("");
    } finally {
      setLoading(false);
    }
  }
  if (loading) return <SpinnerFullPage />;
  return (
    <div className="contact-us">
      <h1>Contact US</h1>
      {!loading && (
        <form className="contact-us-form" onSubmit={handleSubmit}>
          <div>
            <input
              placeholder="First name"
              value={firstName}
              onChange={(e) => setFirstName((current) => e.target.value)}
            />
            <input
              placeholder="Last name"
              value={lastName}
              onChange={(e) => setLastName((current) => e.target.value)}
            />
          </div>
          <input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail((current) => e.target.value)}
          />
          <textarea
            placeholder="Message..."
            value={message}
            onChange={(e) => setMessage((current) => e.target.value)}
          ></textarea>
          <button type="submit">Send</button>
          {showSuccessMessage && <p id="success"> {showSuccessMessage}</p>}
          {error && (
            <p id="error">Ooops, error happend. Please double check your email and try again.</p>
          )}
          {loading && loading}
        </form>
      )}
    </div>
  );
}

export default Contact;
