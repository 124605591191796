import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

// Pages
import MembersInvite from "./pages/MembersInvite/Members";
import Login from "./pages/Login/Login";
import SignUp from "./pages/Signup/Signup";
import ChatsMain from "./pages/Chat/ChatMain";
import PaymentSuccess from "./pages/PaymentSubscription/PaymentSuccess";
import PaymentCancel from "./pages/PaymentSubscription/PaymentCancel";
import Contact from "./pages/ContactUs/ContactUs";
import AboutUs from "./pages/About/aboutUs";
import Payouts from "./pages/ResidualPayouts/ResidualPayouts";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import SendEmailToResetPassword from "./pages/ResetPassword/SendEmailToResetPassword";
import ClassifiedCategories from "./pages/Classified/ClassifiedCategories";
import Error404 from "./pages/ErrorPages/Error404";
import PayPalSubscription from "./pages/PayPalSubscription/PayPalSubscription";
import Classified from "./pages/Classified/Classified";
import ClassifiedProductDetail from "./pages/Classified/ClassifiedProductDetail";
import ClassifiedPublishPost from "./pages/Classified/ClassifiedPublishPost";
import Advertise from "./pages/Advertise/Advertise";
import CreateNewAd from "./pages/Advertise/CreateNewAd";
import AdDetails from "./pages/Advertise/AdDetails";
import AdEdit from "./pages/Advertise/AdEdit";

// Components
import Navbar from "./components/navbar/Navbar";

// Contexts
import { AuthProvider } from "./context/AuthContext";
import { ClassifiedProvider } from "./context/ClassifiedContext";
import { ChatProvider } from "./context/ChatContext";

import { AdvertiseProvider } from "./context/AdContext";
import ClassifiedProductEdit from "./pages/Classified/ClassifiedProductEdit";
import { WebsiteInfoProvider } from "./context/WebsiteInfoContext";
import { UserInfoProvider } from "./context/UserContext";
import EditProfile from "./pages/EditProfile/EditProfile";

// const backendURL = "http://127.0.0.1:8000";
const backendURL = "https://chainpage-aa5cbbfd5466.herokuapp.com";

function App() {
  const [permission, setPermission] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const userCheck = localStorage.getItem("authTokens")
    ? jwtDecode(localStorage.getItem("authTokens"))
    : null;

  useEffect(function () {
    if (!userCheck) {
      setPermission(true);
      return;
    }

    async function userPermission() {
      try {
        setIsLoading(true);
        const response = await fetch(`${backendURL}/api/user/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: userCheck }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data["error"]);
        }

        setCurrentUser(data["username"]);
        setPermission(true);
      } catch (error) {
        console.error(error);
        setError(error.message);
        setPermission(false);
      } finally {
        setIsLoading(false);
      }
    }
    userPermission();
  }, []);

  return (
    <Router>
      <AuthProvider>
        <UserInfoProvider>
          <WebsiteInfoProvider>
            <ChatProvider>
              <AdvertiseProvider>
                <ClassifiedProvider>
                  <Navbar />
                  <Routes>
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/invite/:slug/:slug" element={<SignUp />} />
                    <Route exact path="/contact-us/" element={<Contact />}></Route>
                    <Route exact path="/about-us/" element={<AboutUs />}></Route>
                    <Route exact path="/payment-cancel" element={<PaymentCancel />} />
                    <Route exact path="/payment-success" element={<PaymentSuccess />} />
                    <Route exact path="/reset-password" element={<ResetPassword />} />
                    <Route
                      exact
                      path="/send-reset-password"
                      element={<SendEmailToResetPassword />}
                    />
                    <Route
                      exact
                      path="/paypal-subscription"
                      element={<PayPalSubscription setPermission={setPermission} />}
                    />

                    <Route
                      exact
                      path="/user/:slug"
                      element={
                        <MembersInvite
                          permission={permission}
                          isLoading={isLoading}
                          error={error}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/user/:slug/:slug/"
                      element={
                        <MembersInvite
                          permission={permission}
                          isLoading={isLoading}
                          error={error}
                        />
                      }
                    />
                    <Route path="edit-profile" element={<EditProfile />} />
                    {currentUser ? (
                      <Route path="*" element={<Navigate to={`/user/${currentUser}`} replace />} />
                    ) : (
                      <>
                        <Route path="*" element={<Error404 />} />
                        <Route exact path="/" element={<Login />} />
                      </>
                    )}
                    <Route
                      exact
                      path="/residual-payouts/:slug/:slug/paypal-changed"
                      element={<Payouts emailChanged={true} />}
                    ></Route>
                    {permission && (
                      <>
                        <Route exact path="/chats" element={<ChatsMain />} />
                        <Route exact path="/residual-payouts/" element={<Payouts />}></Route>
                        <Route exact path="classified" element={<Classified />}></Route>
                        <Route
                          exact
                          path="classified/categories"
                          element={<ClassifiedCategories />}
                        />
                        <Route
                          exact
                          path="classified/:slug"
                          element={<ClassifiedProductDetail />}
                        />
                        <Route
                          exact
                          path="classified/:slug/edit"
                          element={<ClassifiedProductEdit />}
                        />
                        <Route
                          exact
                          path="classified/publish-post/"
                          element={<ClassifiedPublishPost />}
                        />
                        <Route exact path="advertise" element={<Advertise />} />
                        <Route exact path="advertise/new-ad" element={<CreateNewAd />} />
                        <Route exact path="advertise/ad/:id" element={<AdDetails />} />
                        <Route exact path="advertise/ad/:id/edit" element={<AdEdit />} />
                      </>
                    )}
                  </Routes>
                </ClassifiedProvider>
              </AdvertiseProvider>
            </ChatProvider>
          </WebsiteInfoProvider>
        </UserInfoProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
