import { useEffect, useState } from "react";
import useSendGetRequest from "../../hooks/sendGetRequest";
import SpinnerFullPage from "../FullPageLoader/SpinnerFullPage";

function ClassifiedCategoriesSelect({ category, setData }) {
  const { data: categories, loading } = useSendGetRequest("/api/categories");
  const [selectCategory, setSelectCategory] = useState(category);

  function categoryChange(e) {
    setData((current) => ({
      ...current,
      category: e.target.value,
      subcategory: categories[e.target.value][0]["name"],
    }));
    setSelectCategory(e.target.value);
  }

  useEffect(
    function () {
      if (!categories) return;
      setSelectCategory(Object.keys(categories)[0]);
      setData((current) => ({
        ...current,
        category: Object.keys(categories)[0],
        subcategory: Object.values(categories)[0][0].name,
      }));
    },
    [categories, setData]
  );

  if (loading) return <SpinnerFullPage />;
  return (
    <>
      <label>Category</label>
      <select onChange={(e) => categoryChange(e)} value={selectCategory} required>
        {Object.keys(categories).map((category, i) => (
          <option value={category} key={i}>
            {category}
          </option>
        ))}
      </select>

      <label>Subcategory</label>

      <select
        onChange={(e) => setData((current) => ({ ...current, subcategory: e.target.value }))}
        required
      >
        {categories[selectCategory]?.map((subcategory, i) => (
          <option value={subcategory} key={i}>
            {subcategory["name"]}
          </option>
        ))}
      </select>
    </>
  );
}

export default ClassifiedCategoriesSelect;
