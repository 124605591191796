import { Link } from "react-router-dom";
import styles from "./ClassifiedArticle.module.css";

import ProductPlaceholder from "../../Images/placeholder.webp";

function ClassifiedArticle({ article }) {
  return (
    <Link to={`${article.id}`}>
      <div className={styles.product}>
        <img
          src={article.image ? article.image : ProductPlaceholder}
          alt="product"
          id={styles.mainProductImage}
        />
        <h4 id={styles.productTitle}>{article.title}</h4>
        <p id={styles.productDescription}>
          {article.description.substring(0, 100)} {article.description.length > 100 && "..."}
        </p>
        <p id={styles.productPrice}>{article.price} USD</p>
      </div>
    </Link>
  );
}

export default ClassifiedArticle;
