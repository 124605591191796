function PayoutMainInfo({
  handleWithdrawAmountChange,
  handleOpenModal,
  userBalance,
  handlePayout,
  withdrawAmount,
  hasPayPalEmail,
}) {
  return (
    <div className="top-info">
      <p id="current-balance">
        Current balance: <span>{userBalance} USD</span>
      </p>
      <div className="withdraw-box box">
        <p>Withdraw:</p>
        <input value={withdrawAmount} onChange={(e) => handleWithdrawAmountChange(e)} id="input" />
        <p id="currency">USD</p>
      </div>
      <button id="btn" onClick={handleOpenModal}>
        {hasPayPalEmail ? "Change" : "Add"} PayPal email
      </button>
      <button id="make-payout" onClick={handlePayout}>
        Payout now
      </button>
    </div>
  );
}

export default PayoutMainInfo;
