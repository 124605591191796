import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Classified.module.css";

import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

import ClassifiedProductsList from "../../components/ClassifiedComponents/ClassifiedProductsList";

import useSendGetRequest from "../../hooks/sendGetRequest";
import ClassifiedProvider from "../../context/ClassifiedContext";

function Classified() {
  const { category } = useContext(ClassifiedProvider);
  const navigate = useNavigate();

  const { data, errorMsg, loading } = useSendGetRequest(`/api/classified-list/${category}`);

  if (loading) return <SpinnerFullPage />;
  if (errorMsg) return <ErrorMessage errorMessage={errorMsg} />;
  return (
    <div className={styles.classified}>
      <button id={styles.categoriesBtn} onClick={() => navigate("categories")}>
        Categories
      </button>
      <h1>Classified</h1>
      <p id={styles.selectedCategory}>Category: {category}</p>
      <button id={styles.publishPost} onClick={() => navigate("publish-post/")}>
        Publish post
      </button>
      <ClassifiedProductsList data={data} />
    </div>
  );
}

export default Classified;
