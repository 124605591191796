import { useNavigate } from "react-router-dom";

import styles from "./ClassifiedCategories.module.css";

import useSendGetRequest from "../../hooks/sendGetRequest";

import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import Category from "../../components/ClassifiedComponents/ClassifiedCategory";
import BackBtn from "../../components/BackBtn";
import { useContext } from "react";
import ClassifiedProvider from "../../context/ClassifiedContext";

function ClassifiedCategories() {
  const { data, errorMsg, loading } = useSendGetRequest("/api/categories");
  const { handleCategorySelect } = useContext(ClassifiedProvider);
  const navigate = useNavigate();

  if (loading) return <SpinnerFullPage />;
  if (errorMsg) return <ErrorMessage errorMessage={errorMsg} />;
  return (
    <div>
      <BackBtn handleClick={() => navigate(-1)} />
      <p id={styles.allCategories} onClick={() => handleCategorySelect(null)}>
        Back to all categories
      </p>
      <div className={styles.categories}>
        {Object.keys(data).map((category) => (
          <Category data={data} category={category} />
        ))}
      </div>
    </div>
  );
}

export default ClassifiedCategories;
