import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";

import useSendGetRequest from "../../hooks/sendGetRequest";

import styles from "./AdEdit.module.css";

import AuthProvider from "../../context/AuthContext";
import AdvertiseProvider from "../../context/AdContext";
import ImageEditField from "../../components/reusableComponents/ImageEditField";

function AdEdit() {
  const { id } = useParams();

  const { errorMsg, loading, data } = useSendGetRequest(`/api/ad-detail/${id}/`);
  const { user } = useContext(AuthProvider);
  const { handleEdit } = useContext(AdvertiseProvider);
  const {
    data: classified,
    errorMsg: classifiedError,
    loading: loadingClassified,
  } = useSendGetRequest(`/api/get-my-classified/${user["user_id"]}/`);

  const [editedData, setEditedData] = useState({
    title: "",
    description: "",
    contact: "",
    user: user["email"],
    id: id,
    classifiedArticle: null,
  });
  const [image, setImage] = useState();
  console.log(data);
  useEffect(
    function () {
      console.log(data?.[0].classified_title);
      setEditedData((current) => ({
        ...current,
        title: data?.[0].title,
        description: data?.[0].description,
        contact: data?.[0].contact_preference,
        file: data?.[0].file,
        classifiedArticle: data?.[0].classified_article,
      }));
    },
    [data]
  );

  function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();

    const AJAXData = JSON.stringify({
      title: editedData.title,
      description: editedData.description,
      contact: editedData.contact,
      user: editedData.user,
      id: editedData.id,
      classifiedArticle: editedData.classifiedArticle,
    });
    console.log(image);
    formData.append("post_data", AJAXData);
    formData.append("image", image);
    handleEdit(formData, id);
  }

  if (loading || !editedData || loadingClassified) return <SpinnerFullPage />;
  if (errorMsg || classifiedError) return <ErrorMessage errorMessage={errorMsg} />;

  return (
    <div>
      <h1>Advertise your new amazing product or service!</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.smallInput}>
          <input
            type="text"
            placeholder="Title..."
            value={editedData.title}
            onChange={(e) => setEditedData((current) => ({ ...current, title: e.target.value }))}
          />
          <input
            type="text"
            placeholder="Contact preference"
            value={editedData.contact}
            onChange={(e) => setEditedData((current) => ({ ...current, contact: e.target.value }))}
          />
        </div>
        <textarea
          placeholder="Description..."
          rows={10}
          value={editedData.description}
          onChange={(e) =>
            setEditedData((current) => ({ ...current, description: e.target.value }))
          }
        ></textarea>

        <label>Connect to classified article</label>
        <select
          value={editedData.classifiedArticle}
          onChange={(e) =>
            setEditedData((current) => ({ ...current, classifiedArticle: e.target.value }))
          }
        >
          <option value={null}>None</option>
          {classified.map((item) => (
            <option value={item.id}>{item.title}</option>
          ))}
        </select>

        <ImageEditField editedData={editedData.file} bucketName="ads" setImage={setImage} />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
}

export default AdEdit;
