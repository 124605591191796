import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const PaymentCancel = () => {
  const { user, backendUrl, userList } = useContext(AuthContext);
  const [permission, setPermission] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
      return;
    }
  }, [navigate, user]);

  useEffect(() => {
    async function getUser() {
      try {
        const response = await fetch(`${backendUrl}/api/user/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: user }),
        });
        if (!response.ok) {
          throw new Error("Error occured!");
        }
        setPermission(false);
        navigate(`/user/${userList[0]}`, { replace: true });
      } catch {
        setPermission(true);
      }
    }
    getUser();
  }, [backendUrl, setPermission, navigate, userList, user]);

  return <div>{permission ? <h1>Oops, something went wrong... Please try again</h1> : ""}</div>;
};

export default PaymentCancel;
