function ChatItem({ chat, handleOpenChat, chatOpen, userProfileImage, notification }) {
  console.log(chat["pk"], notification);
  console.log(chat["pk"] === notification);
  console.log(chat["notifications"]);

  const hasNotification = chat["pk"] === notification || chat["notifications"];

  return (
    <div
      className={`chat-item ${chat["pk"] === chatOpen ? "active" : ""}${
        hasNotification && "chat-notification"
      }`}
      onClick={() => handleOpenChat(chat["pk"])}
    >
      <img src={userProfileImage} alt="user-profile" className="user-icon" loading="lazy" />
      <div className="text">
        <h3>{chat["room_name"]}</h3>
        <p>Lorem, ipsum dolor sit amet.</p>
      </div>
    </div>
  );
}

export default ChatItem;
