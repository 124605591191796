import React, { useRef, useState } from "react";

// Components
import { ParentProfiles, MainUserProfile } from "./ChainLink.js";
import MessageBox from "./message.js";
import TopCircle from "./TopCircleStats";

// CSS
import "./Main.css";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../BackBtn.js";
import { useUserInfo } from "../../../context/UserContext.js";
import SpinnerFullPage from "../../FullPageLoader/SpinnerFullPage.js";

const Main = (url) => {
  const [messageVisible, setMessageVisible] = useState(false);
  const { userList, currentUser, loading } = useUserInfo();

  const navigate = useNavigate();
  const firstInvitePage = useRef();

  const isValidUUID =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
      window.location.pathname.split("/")[3]
    );

  const currentURL = window.location.pathname.split("/")[2];
  if (isValidUUID) {
    const formatUrl = `${currentURL}/${window.location.pathname.split("/")[3]}`;
    console.log(formatUrl);
    firstInvitePage.current = formatUrl;
  }

  function handleMessageHover(message) {
    setMessageVisible(message);
  }

  function handleMessageClose() {
    setMessageVisible(false);
  }

  function goToFirstInvitePage() {
    console.log(firstInvitePage);
    navigate(`/user/${firstInvitePage.current}`);
  }
  console.log(currentUser);

  if (loading || !userList) return <SpinnerFullPage />;
  return (
    <div className="main-content">
      {!currentUser && firstInvitePage && <BackBtn handleClick={goToFirstInvitePage} />}
      <div className="user-profiles">
        {userList[0] ? (
          <>
            <MainUserProfile
              mainUser={userList[0]}
              onMessageHover={handleMessageHover}
              onHoverLeave={handleMessageClose}
              urlChange={url}
            />
            {userList.map((user, index) => {
              if (index === 0) return "";

              return (
                <ParentProfiles
                  last={index === 4 ? true : false}
                  currentUser={user}
                  key={index}
                  urlChange={url}
                />
              );
            })}
          </>
        ) : (
          <div className="user-not-found">
            <h1>Error 404</h1>
            <p> User not found</p>
          </div>
        )}
      </div>
      {messageVisible && <MessageBox username={userList[0]} message={messageVisible} />}
      <TopCircle />
    </div>
  );
};

export default Main;
