import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../../context/AuthContext";

import styles from "./PayPalSubscription.module.css";
import { useUserInfo } from "../../context/UserContext";

function PayPalSubscription({ setPermission }) {
  const { user, backendUrl } = useContext(AuthContext);
  const { currentUser } = useUserInfo();
  const navigate = useNavigate();

  const [payPalEmail, setPayPalEmail] = useState();
  const [errorMsg, setErrorMsg] = useState();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await fetch(`${backendUrl}/api/pay-pal-subscription/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: user, pay_pal_email: payPalEmail }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data["errorMsg"]);
      }

      navigate(`/user/${currentUser}`);
      setPermission(true);
    } catch (error) {
      setErrorMsg(error.message);
    }
  }

  if (errorMsg) return <h1>Error happened while trying to subscribe you. {errorMsg}</h1>;
  return (
    <div>
      <h1>Subscribe to Chainpage</h1>
      <form onSubmit={handleSubmit} className={styles.PayPalForm}>
        <input
          value={payPalEmail}
          onChange={(e) => setPayPalEmail(e.target.value)}
          placeholder="Your PayPal email..."
        />
        <button type="submit">Subscribe</button>
      </form>
    </div>
  );
}

export default PayPalSubscription;
