import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

import "./Login.css";
import PasswordInput from "../../components/reusableComponents/PasswordInput";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";

function LoginPage() {
  const { isLoading, loginUser, user, loginErrorMessage } = useContext(AuthContext);
  const [passowrdIsVisible, setPassowrdIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
      return;
    }
  }, [user, navigate]);

  if (isLoading) return <SpinnerFullPage />;
  return (
    <div className="login">
      {loginErrorMessage && loginErrorMessage}

      <form method="post" onSubmit={loginUser}>
        <div>
          <input type="email" placeholder="Email" name="email" />
        </div>
        <PasswordInput
          passowrdIsVisible={passowrdIsVisible}
          setPassowrdIsVisible={setPassowrdIsVisible}
        />
        <Link className="forgot-password" to="/send-reset-password">
          Forgot password?
        </Link>
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default LoginPage;
