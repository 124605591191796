import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import styles from "./ClassifiedProductDescription.module.css";

import ChatProvider from "../../context/ChatContext";

import EditIcon from "../../Images/edit.png";
import Bin from "../../Images/bin.png";
import ClassifiedModalDelete from "./ClassifiedModalDelete";
import { useUserInfo } from "../../context/UserContext";

function ClassifiedProductDescription({ data, showModal, setShowModal }) {
  const { handleContactUser } = useContext(ChatProvider);
  const { currentUser, getInformationOfLoggedInUser } = useUserInfo();

  const navigate = useNavigate();

  function handleAskDelete(e) {
    e.preventDefault();
    setShowModal((current) => !current);
  }

  useEffect(
    function () {
      getInformationOfLoggedInUser();
    },
    [getInformationOfLoggedInUser]
  );
  const sameUser = currentUser === data[0].user;

  return (
    <div className={` ${styles.productDetails} `}>
      {showModal && <ClassifiedModalDelete handleAskDelete={handleAskDelete} data={data[0]} />}
      <div className={`${showModal && styles.blur}`}>
        <h1 id={styles.title}>{data[0].title}</h1>
        <small>
          Published by: <Link to={`/user/${data[0].user}`}> {sameUser ? "You" : data[0].user}</Link>
        </small>
        <p id={styles.description}>{data[0].description}</p>
        <p id={styles.cost}>{data[0].price} USD</p>
        <div className={styles.category}>
          <p>Category: {data[0].category}</p>
          <p>Subcategory: {data[0].sub_category}</p>
        </div>
        {sameUser && (
          <div className={styles.authorActionBtns}>
            <img src={Bin} alt="delete" onClick={() => setShowModal(true)} />
            <img src={EditIcon} alt="edit" onClick={() => navigate("edit")} />
          </div>
        )}
        {!sameUser && (
          <button onClick={() => handleContactUser(data[0].user)}>Contact seller</button>
        )}
      </div>
    </div>
  );
}

export default ClassifiedProductDescription;
