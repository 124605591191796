import { useParams } from "react-router-dom";

import styles from "./ClassifiedProductDetail.module.css";

import useSendGetRequest from "../../hooks/sendGetRequest";

import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import SpinnerFullPage from "../../components/FullPageLoader/SpinnerFullPage";
import ImgComponent from "../../components/ImgComponent";
import ClassifiedProductDescription from "../../components/ClassifiedComponents/ClassifiedProductDescription";
import { useState } from "react";

function ClassifiedProductDetail() {
  const { slug } = useParams();
  const [showModal, setShowModal] = useState(false);

  const styleImg = { width: "100%", borderRadius: "10px", filter: showModal && "blur(8px)" };

  const { errorMsg, loading, data } = useSendGetRequest(`/api/classified-artile-detail/${slug}/`);

  if (loading) return <SpinnerFullPage />;
  if (errorMsg) return <ErrorMessage errorMessage={errorMsg} />;

  return (
    <div className={styles.product}>
      <ImgComponent image={data[0].image} alt="product" styleImg={styleImg} />
      <ClassifiedProductDescription
        data={data}
        id={slug}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
}

export default ClassifiedProductDetail;
