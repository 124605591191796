import React, { useContext, useEffect, useState, memo } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";

// Images
import AdvertiseProvider from "../../../context/AdContext";
import SpinnerFullPage from "../../FullPageLoader/SpinnerFullPage";
import { useUserInfo } from "../../../context/UserContext";

const ButtonAndVideo = function ({ onVideo }) {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user, backendUrl, userList } = useContext(AuthContext);

  const { handleCopyInviteLink, copyInviteLink, copyError, setCopyInviteLink } = useUserInfo();

  const { getNewAds, currentAdLink, isAd, loading } = useContext(AdvertiseProvider);

  useEffect(
    function () {
      const id = setInterval(function () {
        getNewAds();
        console.log("call");
      }, 20000);

      return () => clearInterval(id);
    },
    [currentAdLink, getNewAds]
  );

  useEffect(
    function () {
      if (copyInviteLink) {
        const id = setInterval(function () {
          setCopyInviteLink("");
        }, 3000);
      }
    },
    [copyInviteLink, setCopyInviteLink]
  );

  async function handleJoin() {
    const UUIDInvite = window.location.href.split("user/")[1].split("/")[1];
    const username = window.location.href.split("user/")[1].split("/")[0];
    console.log(UUIDInvite, username);
    if (!UUIDInvite) {
      setError("You can't join in chainpage without proper invite link!");
    } else {
      const response = await fetch(`${backendUrl}/api/get-user-data/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: username }),
      });

      if (!response.ok) {
        setError("Something went wrong! Please try again later.");
        return;
      }
      const data = await response.json();
      console.log(data);
      if (UUIDInvite === data["uuid"]) {
        navigate(`/invite/${data.user_data[0][0]}/${UUIDInvite}`);
      } else {
        setError("Invalid invite link! Please try again.");
      }
    }
  }
  console.log(loading);

  if (!currentAdLink.link) return <SpinnerFullPage />;
  const isImage = isAd(currentAdLink.link);

  console.log(currentAdLink);

  return (
    <>
      <div className="video-box" onClick={onVideo}>
        {currentAdLink.id !== -1 ? (
          <Link to={`/advertise/ad/${currentAdLink.id}`}>
            {isImage ? (
              <img src={currentAdLink.link} alt="invite video" id="videoThumbnail" loading="lazy" />
            ) : (
              <video id="videoThumbnail" autoPlay={true} muted={true}>
                <source src={currentAdLink.link} type="video/mp4" />
              </video>
            )}
          </Link>
        ) : (
          <video id="videoThumbnail" autoPlay={true} muted={true}>
            <source src={currentAdLink.link} type="video/mp4" />
          </video>
        )}
      </div>
      {user ? (
        <>
          {copyInviteLink && <p id="invitePopup">Invite link copied</p>}
          <button
            id="join-btn"
            onClick={() => {
              handleCopyInviteLink();
            }}
          >
            Invite link
            {!copyInviteLink ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-clipboard-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-clipboard-check-fill"
                viewBox="0 0 16 16"
              >
                <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
              </svg>
            )}
          </button>
          {copyError && <p id="error">{copyError}</p>}
        </>
      ) : (
        <>
          {error && <p id="error">{error}</p>}
          <button id="join-btn" onClick={handleJoin}>
            join chainpage
          </button>
        </>
      )}
    </>
  );
};
function arePropsEqual(prevProps, nextProps) {
  return prevProps.id === nextProps.id;
}
export default React.memo(ButtonAndVideo, arePropsEqual);
