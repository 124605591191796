import Message from "./message.js";

function PreviousMessages({ messages, user }) {
  return (
    <div className="chat-messages">
      {messages.map((message, i) => (
        <Message message={message} currentUser={user["email"]} key={i} />
      ))}
    </div>
  );
}

export default PreviousMessages;
