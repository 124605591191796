import React, { useState } from "react";

import SignupHeader from "../../components/signupComponents/SignupHeader";
import SignupForm from "../../components/signupComponents/SignupForm";

import "./Signup.css";

function SignUp() {
  const [registerError, setRegisterError] = useState(null);
  const [invitedBy, setInvitedBy] = useState(null);

  return (
    <div className="signup">
      <SignupHeader invitedBy={invitedBy} />
      {registerError && <p id="error">{registerError && registerError}</p>}
      <SignupForm
        onInvitedBy={setInvitedBy}
        invitedBy={invitedBy}
        onRegisterError={setRegisterError}
      />
    </div>
  );
}

export default SignUp;
