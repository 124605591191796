import styles from "./BackBtn.module.css";

function BackBtn({ handleClick }) {
  return (
    <div id={styles.backBtn} onClick={handleClick}>
      <svg
        height="512px"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 512 512"
        width="512px"
        fill="white"
      >
        <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
      </svg>
    </div>
  );
}

export default BackBtn;
