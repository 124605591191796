import File from "../../../Images/file.png";

function MessageBox({ onButtonClicked, value, onMessageChange, onFileUpload, uploadedFiles }) {
  function handleChange(e) {
    const value = e.target.files[0];
    console.log(value);
    onFileUpload((current) => [...current, value]);
  }

  function removeFile(file) {
    console.log(file);
    onFileUpload((current) => [...current.filter((curFile) => curFile !== file && file)]);
  }

  return (
    <div className="input-wrapper">
      <form
        className={`message-box ${uploadedFiles.length > 0 && "more-space"}`}
        onSubmit={onButtonClicked}
        enctype="multipart/form-data"
      >
        <label id="file-upload">
          <input
            type="file"
            placeholder="Page icon"
            name="image"
            accept="image/*"
            onChange={handleChange}
            id="image"
          />
          +
        </label>
        <textarea placeholder="Write a message" value={value} onChange={onMessageChange}></textarea>
        <button type="submit">send</button>
      </form>
      {uploadedFiles.length > 0 && (
        <div className="files">
          {uploadedFiles.map((file) => {
            console.log(file);

            return (
              <div className="file">
                <p id="remove-file" onClick={() => removeFile(file)}>
                  &times;
                </p>
                <img src={File} alt="file" loading="lazy" />
                <p>{file.name.length > 20 ? file.name.substring(0, 20) + "..." : file.name}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default MessageBox;
